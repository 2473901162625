import React, { Component } from 'react'

import './FifthSection.scss';

import { PHONE_NUMBER_STR } from 'global_variables';

import MapIcon from './images/map.png';
import GoogleMap from 'components/GoogleMap';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';
import WhiteButton from 'components/Buttons/WhiteButton';
import Contact from 'components/Contact';

class FifthSection extends Component {

  constructor(props){
    super(props);

    this.showContactForm = this.showContactForm.bind(this);
    this.closeContactForm = this.closeContactForm.bind(this);
    this.renderContactForm = this.renderContactForm.bind(this);

    this.state = {
      showContactForm: false,
      address: null,
      servicedArea: false,
    }

  }

  showContactForm(address, servicedArea) {
    this.setState({
      showContactForm: true,
      address,
      servicedArea,
    });
  }

  closeContactForm() {
    this.setState({
      showContactForm: false,
      address: null,
      servicedArea: false
    });
  }

  renderContactForm() {
    const { servicedArea } = this.state;

    return (
      <div className='secondsection__contact'>

        <div className='secondsection__contact-close'>
          <span className='glyphicon glyphicon-remove' onClick={this.closeContactForm}></span>
        </div>

        {!servicedArea && <h3>Want to talk to a rep right away about servicing your location?</h3>}
        {servicedArea && <h3>Want to schedule your <strong>free estimate</strong> right away?</h3>}

        <GreenPhoneButton>CALL NOW <strong>{PHONE_NUMBER_STR}</strong></GreenPhoneButton>
        
        <div className='secondsection__contact-break'></div>

        <h3>Or want us to get in touch with you?</h3>

        {!servicedArea && <Contact address={this.state.address} message={`Hi, I would like to know if you provide service at my location. Please get back to me by email or phone.`} />}
        {servicedArea && <Contact address={this.state.address} message={`Hi, I would like to schedule an estimate for my landscaping needs. Please get back to me by email or phone.`} />}

        <WhiteButton cb={this.closeContactForm}>Close</WhiteButton>
      </div>      
    );
  }

  render() {
    return (
      <div className="fifthsection">

       <div className='fifthsection__map'>
          <img src={MapIcon} alt='mapicon'/>
        </div>

        <div className='fifthsection__header'>
          <h1>We service all of St. George and surrounding areas.</h1>
        </div>

        <div className='fifthsection__subheader'>
          Check to see if your home is covered in our area!  
        </div>

        {this.state.showContactForm && this.renderContactForm()}

        <div className='fifthsection__googlemap'>
          <GoogleMap cb={this.showContactForm}/>
        </div>

      </div>
    )
  }

}

export default FifthSection;