import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';

import './MobileMenu.scss';

import GreenButton from 'components/Buttons/GreenButton.jsx';

class MobileMenu extends Component {

  constructor(props){
    super(props);

    this.goHome = this.goHome.bind(this);
    this.goToLink = this.goToLink.bind(this);
    this.goToFreeQuote = this.goToFreeQuote.bind(this);
  }


  goHome() {
    this.props.handleSideMenuClose();
    this.props.history.push('/');
  }

  goToFreeQuote() {
    this.props.handleSideMenuClose();
    this.props.history.push('/contact#freequote');
  }

  goToLink() {
    this.props.handleSideMenuClose();
    let menu = document.getElementById('sidemenu');
    setTimeout(() => {
      menu.scrollTop = 0;
    }, 600);
  }

  render() {
    return (
      <div id="sidemenu" className={ this.props.open ? "sidemenu-open" : "sidemenu-closed" }>

        <div id="sidemenu-topbar-psuedo"></div>

        <div className="sidemenu-header">
          <div className="sidemenu-header-logo" onClick={this.goHome}></div>
        </div>

        <div className="sidemenu-list">
          
          <Link onClick={this.goToLink} to="/about" className="sidemenu-link sidemenu-item sidemenu-item-main ">ABOUT</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#ourteam" className="sidemenu-link sidemenu-item sidemenu-item-sub">OUR TEAM</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#whyus" className="sidemenu-link sidemenu-item sidemenu-item-sub">WHY US?</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#ourstory" className="sidemenu-link sidemenu-item sidemenu-item-sub">OUR STORY</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#reviews" className="sidemenu-link sidemenu-item sidemenu-item-sub">REVIEWS</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#faq" className="sidemenu-link sidemenu-item sidemenu-item-sub">FAQ</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/about#calculator" className="sidemenu-link sidemenu-item sidemenu-item-sub">LANDSCAPE CALCULATOR</Link>
            <div className="sidemenu-item-main-divider"></div>

            <Link onClick={this.goToLink} to="/services" className="sidemenu-link sidemenu-item sidemenu-item-main ">SERVICES</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#landscape-maintenance" className="sidemenu-link sidemenu-item sidemenu-item-sub">LANDSCAPE MAINTENANCE</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#aeration-fertilization" className="sidemenu-link sidemenu-item sidemenu-item-sub">AERATION/FERTILIZATION</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#sprinkler-valve-repair" className="sidemenu-link sidemenu-item sidemenu-item-sub">SPRINKLER/VALVE REPAIR</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#palm-tree-trimming" className="sidemenu-link sidemenu-item sidemenu-item-sub">PALM TREE TRIMMING</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#landscape-installs-and-overhauls" className="sidemenu-link sidemenu-item sidemenu-item-sub">LANDSCAPE INSTALLS AND OVERHAULS</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/services#concrete-work" className="sidemenu-link sidemenu-item sidemenu-item-sub">CONCRETE WORK</Link>
            <div className="sidemenu-item-main-divider"></div>

            <Link onClick={this.goToLink} to="/gallery" className="sidemenu-link sidemenu-item sidemenu-item-main ">GALLERY</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#rock-install" className="sidemenu-link sidemenu-item sidemenu-item-sub">ROCK INSTALL</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#putting-green" className="sidemenu-link sidemenu-item sidemenu-item-sub">PUTTING GREEN</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#cleanups" className="sidemenu-link sidemenu-item sidemenu-item-sub">CLEANUPS</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#front-yard-redesign" className="sidemenu-link sidemenu-item sidemenu-item-sub">FRONT YARD REDESIGN</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#drain-and-rocks" className="sidemenu-link sidemenu-item sidemenu-item-sub">DRAIN AND ROCK</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#leak-fix" className="sidemenu-link sidemenu-item sidemenu-item-sub">LEAK FIX</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#grass-removal" className="sidemenu-link sidemenu-item sidemenu-item-sub">GRASS REMOVAL</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/gallery#concrete" className="sidemenu-link sidemenu-item sidemenu-item-sub">CONCRETE</Link>
            <div className="sidemenu-item-main-divider"></div>

            <Link onClick={this.goToLink} to="/blog" className="sidemenu-link sidemenu-item sidemenu-item-main ">BLOG</Link>
            <div className="sidemenu-item-main-divider"></div>

            <Link onClick={this.goToLink} to="/contact" className="sidemenu-link sidemenu-item sidemenu-item-main ">CONTACT</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/contact#freequote" className="sidemenu-link sidemenu-item sidemenu-item-sub">FREE QUOTE</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/contact#map" className="sidemenu-link sidemenu-item sidemenu-item-sub">MAP</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/contact#specialoffers" className="sidemenu-link sidemenu-item sidemenu-item-sub">SPECIAL OFFERS</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/contact#comejoinus" className="sidemenu-link sidemenu-item sidemenu-item-sub">COME JOIN OUR TEAM</Link>
            <div className="sidemenu-item-main-divider"></div>
            <Link onClick={this.goToLink} to="/contact#writeareview" className="sidemenu-link sidemenu-item sidemenu-item-sub">WRITE A REVIEW</Link>
            <div className="sidemenu-item-main-divider"></div>
          
        </div>

        <div className="sidemenu-quote-btn">
          <GreenButton cb={this.goToFreeQuote}>
            GET A FREE QUOTE
          </GreenButton>
        </div>
        
        
      </div>
    )
  }

}
export default withRouter(MobileMenu);