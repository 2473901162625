import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Creatable from 'react-select/creatable';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import { PHONE_NUMBER_STR } from 'global_variables';

import WhiteButton from 'components/Buttons/WhiteButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';

import Logo from 'global_images/menu-logo.png';
import FilterIcon from './images/filter-icon.png';
import Calendar from 'global_images/calendar-icon.png';
import Wheelbarrow from 'global_images/wheelbarrow-icon.png';
import Sprinkler from 'global_images/sprinkler-icon.png';
import Shears from 'global_images/shears-icon.png';
import Bush from 'global_images/bush-icon.png';
import ConcreteMixer from 'global_images/concrete-mixer-icon.png';

import SeventhSection from 'components/HomePage/SeventhSection';
import Contact from 'components/Contact';

import './ServicesPage.scss';

const options = [
  { value: 'lawn_mowing', label: 'Lawn mowing' },
  { value: 'edging', label: 'Edging' },
  { value: 'bush_trimming ', label: 'Bush trimming' },
  { value: 'tree_trimming', label: 'Tree trimming' },
  { value: 'pruning', label: 'Pruning' },
  { value: 'weeding', label: 'Weeding' },
  { value: 'aeration', label: 'Aeration' },
  { value: 'fertilization', label: 'Fertilization' },
  { value: 'sprinkler_system_installation', label: 'Sprinkler system installation' },
  { value: 'sprinkler_head_change', label: 'Sprinkler head change' },
  { value: 'sprinkler_valve_repair', label: 'Sprinkler valve repair' },
  { value: 'leaking_sprinkler_valves', label: 'Leaking sprinkler valves' },
  { value: 'drip_line_installation', label: 'Drip line installation' },
  { value: 'palm_tree_trimming', label: 'Palm tree trimming' },
  { value: 'palm_tree_pruning', label: 'Palm tree pruning' },
  { value: 'palm_tree_removal', label: 'Palm tree removal' },
  { value: 'palm_tree_installation', label: 'Palm tree installation' },
  { value: '3D_designed_front_and_back_yards', label: '3D designed front and back yards' },
  { value: 'tree_installation', label: 'Tree installation' },
  { value: 'bush_installation', label: 'Bush installation' },
  { value: 'grass_installation', label: 'Grass installation' },
  { value: 'grass_removal', label: 'Grass removal' },
  { value: 'turf_installation', label: 'Turf installation' },
  { value: 'curbing', label: 'Curbing' },
  { value: 'landscape_overhaul', label: 'Landscape overhaul' },
  { value: 'rock_installation', label: 'Rock installation' },
  { value: 'pathways', label: 'Pathways' },
  { value: 'cleanups', label: 'Cleanups' },
  { value: 'sidewalk_installation', label: 'Sidewalk installation' },
  { value: 'pavement_installation', label: 'Pavement installation' },
  { value: 'stair_installation', label: 'Stair installation' },
  { value: 'patio_installtion', label: 'Patio installation' },
  { value: 'concrete_work', label: 'Concrete work' },
];

const LAWN_MOWING_STR = 'lawn mowing';
const EDGING_STR = 'edging';
const TRIMMING_STR = 'tree trimming bush trimming pruning';
const WEEDING_STR = 'weeding';
const LANDSCAPE_MAINTENANCE_STR = combineStr(LAWN_MOWING_STR, ' ', EDGING_STR, ' ', TRIMMING_STR, ' ', WEEDING_STR);

const AERATION_STR = 'aeration';
const FERTILIZATION_STR = 'fertilization';
const AERATION_FERTILIZATION_STR = combineStr(AERATION_STR, ' ', FERTILIZATION_STR);

const SPRINKLER_SYSTEM_INSTALLATION_STR = 'sprinkler system installation';
const SPRINKLER_HEAD_VALVE_REPAIR_STR = 'sprinkler head change sprinkler valve repair';
const LEAKING_SPRINKLER_VALVES_STR = 'leaking sprinkler valves';
const DRIP_LINE_INSTALLATION_STR = 'drip line installation';
const SPRINKLERS_STR = combineStr(SPRINKLER_SYSTEM_INSTALLATION_STR, ' ', SPRINKLER_HEAD_VALVE_REPAIR_STR, ' ', LEAKING_SPRINKLER_VALVES_STR, ' ', DRIP_LINE_INSTALLATION_STR);

const PALM_TREE_TRIMMING_STR = 'palm tree trimming & removal palm tree pruning';
const PALM_TREE_INSTALLATION_STR = 'palm tree installation';
const PALM_TREES_STR = combineStr(PALM_TREE_TRIMMING_STR, ' ', PALM_TREE_INSTALLATION_STR);

const DESIGNED_FRONT_AND_BACK_YARDS_STR = '3d designed front and back yards';
const TREE_BUSH_INSTALL_STR = 'tree bush installation';
const GRASS_AND_TURF_INSTALL_STR = 'grass and turn installation grass removal';
const CURBING_STR = 'curbing';
const OVERHAULS_STR = 'overhauls';
const ROCK_INSTALLS_STR = 'rock installation';
const PATHWAYS_STR = 'pathways';
const CLEANUPS_STR = 'yard cleanups';
const LANDSCAPE_INSTALLS_STR = combineStr(DESIGNED_FRONT_AND_BACK_YARDS_STR, ' ', TREE_BUSH_INSTALL_STR, ' ', GRASS_AND_TURF_INSTALL_STR, ' ', CURBING_STR, ' ', OVERHAULS_STR, ' ', ROCK_INSTALLS_STR, ' ', PATHWAYS_STR, ' ', CLEANUPS_STR);

const SIDEWALK_STR = 'sidewalk installation pavement installation concrete work patio installation';
const STAIRS_STR = 'stairs installation concrete work';
const CONCRETE_STR = combineStr(SIDEWALK_STR, ' ', STAIRS_STR);


function combineStr() {
  let outputStr = '';

  for (let i = 0; i < arguments.length; i++ ) {
    outputStr = outputStr.concat(arguments[i], ' ');
  }

  return outputStr;
}

class ServicesPage extends Component {

  constructor(props){
    super(props);

    this.goToSection = this.goToSection.bind(this);
    this.goHome = this.goHome.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkNewInput = this.checkNewInput.bind(this);

    this.onBlur = this.onBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onDeleteKey = this.onDeleteKey.bind(this);

    this.closeContactForm = this.closeContactForm.bind(this);

    this.state = {
       selectedOption: null, 
       showContactForm: false,
       newOption: null,
       currInputVal: '',
    }
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (_.has(this.props, ['location', 'hash']) && _.has(prevProps, ['location', 'hash'])) {
      if( this.props.location !== prevProps.location ) {
          this.setState({
            currInputVal: '',
            selectedOption: null
          }, () => {
            this.goToSection(this.props.location.hash.substring(1));
          });
      }
    }
  }

  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goHome() {
    this.props.history.push('/');
  }

  handleChange(selectedOption) {
    if (selectedOption !== null) {
      this.setState({ selectedOption, currInputVal: selectedOption.label });

      if ( '__isNew__' in selectedOption) {
        this.setState({ 
          showContactForm: true, 
          newOption: selectedOption.label,
          currInputVal: selectedOption.label
        });
      }
    } else {
        this.setState({ 
          selectedOption: null,
          currInputVal: ''
        });
    }
  }

  onInputChange(val) {
    if (val !== '') {
      this.setState({
        currInputVal: val
      });
    }
  }

  checkNewInput(inputVal, selectVal, options) {
    for(let i = 0; i < options.length; i++) {
      if ( options[i].label.toLowerCase().includes(inputVal) ) {
        return false;
      }
    }

    return true;
  }

  newInput(newOption) {
    return `Couldn't find what you were looking for? Click here to ask us about "${newOption}"`
  }

  onBlur(e) {
    let val = e.target.value;
    if (val !== '') {
      this.setState({ 
        selectedOption: { value: e.target.value, label: e.target.value },
        currInputVal: val
      });
    }
  }

  onDeleteKey(e) {
    if ( e.keyCode === 8 && this.state.currInputVal.length === 1) {
      this.setState({
        currInputVal: '',
        selectedOption: null
      });
    }
  }

  closeContactForm() {
    this.setState({ showContactForm: false });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="servicespage">

        <div className='servicespage__banner'>
          <div className='servicespage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='servicespage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='servicespage__body'>

          {this.state.showContactForm && <div className='secondsection__contact'>

            <div className='secondsection__contact-close'>
              <span className='glyphicon glyphicon-remove' onClick={this.closeContactForm}></span>
            </div>

            <h3>Want an answer right away about {this.state.newOption}?</h3>

            <GreenPhoneButton>CALL NOW <strong>{PHONE_NUMBER_STR}</strong></GreenPhoneButton>
            
            <div className='secondsection__contact-break'></div>

            <h3>Want us to get in touch with you?</h3>

            <Contact message={`Hi, I would like to know if you provide ${this.state.newOption}.`} />

            <WhiteButton>Close</WhiteButton>
          </div>}

          <div className='servicespage__filter'>    
            <div className='servicespage__filter-icon'>
              <img src={FilterIcon} alt='filtericon' />
            </div>
            <div className='servicespage__filter-box'>
              <Creatable
                value={selectedOption}
                onChange={this.handleChange}
                onInputChange={this.onInputChange}
                options={options}
                placeholder='What service do you need?'
                formatCreateLabel={ this.newInput }
                isValidNewOption={ this.checkNewInput }
                className="servicespage__dropdown-select"
                classNamePrefix="servicespage__dropdown-select"
                onBlur={this.onBlur}
                onKeyDown={this.onDeleteKey}
                isClearable={true}
              />
            </div>
          </div>

          <Element name="landscape-maintenance">
            {LANDSCAPE_MAINTENANCE_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon'>
                <img src={Calendar} alt='calendaricon' />
                <h1>Landscape Maintenance</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {LAWN_MOWING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-lawnmow'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>LAWN MOWING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you are one of our many accounts we see on a weekly basis, then you know we mow 
                          each of our lawns once a week or every other week depending on the time of year. We will only mow if it is needed.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {EDGING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-edging'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>EDGING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Edging is very important to a clean looking yard. We edge around each part of the lawn to 
                          define a clean line between the grass, sidewalk, driveways, and patios. This helps create beautiful transitions from 
                          section to section.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {TRIMMING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-trimming'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>TRIMMING/PRUNING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you want your yard to stay clean and classy, you need to maintain the growth on your bushes and trees. 
                          This is a service we do with most of our weekly maintenance accounts to keep them clean and fresh. 
                        </div>
                      </div>
                    </div>
                  </div>}
                  {WEEDING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-weeding'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>WEEDING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you have a yard or dirt, you have weeds and it’s a never-ending battle. 
                          A service we offer with our maintenance is weed control. We will pull some weed 
                          and lay down a barrier of weed spray to keep the weeds under control.
                        </div>
                      </div>
                    </div>
                  </div>}

                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>}
          </Element>

          <Element name="areation-fertilization">
            {AERATION_FERTILIZATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon'>
                <img src={Wheelbarrow} alt='wheelbarrowicon' />
                <h1>Aeration/Fertilization</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {AERATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-aeration'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>AERATION</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Aerating is very important. We perforate the soil with small holes to allow air, water, and 
                          nutrients to penetrate the grass roots. This helps your lawn grow stronger and more vigorous.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {FERTILIZATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-fertilization'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>FERTILIZATION</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Fertilizing your lawn is important and something we do throughout the year 
                          to give the lawn a little something extra to help through the hot months 
                          and keep your grass the greenest on the block.
                        </div>
                      </div>
                    </div>
                  </div>}

                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>}
          </Element>

          <Element name="sprinkler-valve-repair">
            {SPRINKLERS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon'>
                <img src={Sprinkler} alt='sprinklericon' />
                <h1>Sprinklers</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {SPRINKLER_SYSTEM_INSTALLATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-sprinkler'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>SPRINKLER SYSTEM INSTALLATION</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          We can tie into an existing sprinkler system or build an entire sprinkler system from scratch.  
                          This includes drain systems and all the tree and bush drip systems. 
                        </div>
                      </div>
                    </div>
                  </div>}
                  {SPRINKLER_HEAD_VALVE_REPAIR_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-sprinklerrepair'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>SPRINKLER HEAD/VALVE REPAIR</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you have an existing system and have parts that aren’t working, sprinkler heads that aren’t spraying, or
                          lawn that is dying, we can fix all and any issue or even upgrade the entire system.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {LEAKING_SPRINKLER_VALVES_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-leakingsprinkler'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>LEAKING SPRINKLER VALVES</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you are like the many homeowners that have had water leaks, you will understand that it is not a 
                          fun situation to be in.  Leave this one up to us and we will find and repair any leaks you have in your yard.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {DRIP_LINE_INSTALLATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-dripline'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>DRIP LINE INSTALLATION</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Take the hassle out of hand watering your plants and bushes. We can run lines to each and every one 
                          of your trees and bushes and hook it up to your sprinkler system. 
                          It's as easy as turning on the timer and just letting them grow. 
                        </div>
                      </div>
                    </div>
                  </div>}

                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>}
          </Element>

          <Element name="palm-tree-trimming">
            {PALM_TREES_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon servicespage__landscape-maintenance-icon-shears'>
                <img src={Shears} alt='shearsicon' />
                <h1>Palm Trees</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {PALM_TREE_TRIMMING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-palmtreeremoval'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>PALM TREE TRIMMING/PRUNING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Tired of seeing your palms trees covered in old dead frond or even cutting your hands on sharp fronds? 
                          Then let us spruce up your yard and clean up and remove all the dead and unhealthy fronds, giving your palm a 
                          fresh new clean look.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {PALM_TREE_INSTALLATION_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-palmtreeinstall'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>PALM TREE INSTALL & REMOVAL</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Looking to give your home a paradise feel? Then palm trees are where it's at!
                          We can install new small palm trees or remove old small palm trees.
                        </div>
                      </div>
                    </div>
                  </div>}

                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>}
          </Element>

          <Element name="landscape-installs-and-overhauls">
            {LANDSCAPE_INSTALLS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon servicespage__landscape-maintenance-icon-bush'>
                <img src={Bush} alt='bushicon' />
                <h1>Landscape Installs and Overhauls</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {DESIGNED_FRONT_AND_BACK_YARDS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-designedfrontyards'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>3D DESIGNED FRONT/BACK YARDS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          This is a great feature to help build your desired backyard. 
                          We can give you a 3D video of what your new yard will look like when it's done. This technology 
                          helps us give a perfect understanding of what you want in a yard before we even start. 
                          This will ensure that you get the exact yard that you want!
                        </div>
                      </div>
                    </div>
                  </div>}
                  {GRASS_AND_TURF_INSTALL_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-grassinstall'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>GRASS/TURF INSTALL & REMOVAL</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If you are looking for grass or don’t want the hassle of maintaining grass, 
                          we can also install artificial grass. Either option will bring the finishing touch to your yard. We can also completely 
                          remove your existing grass and replace it with something completely different.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {TREE_BUSH_INSTALL_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-bushinstall'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>TREE/BUSH INSTALL</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Are you wanting more than just rock and grass? If it's for shade or just to give your yard a 
                          little more style, we can install trees or bushes and run a drip system to keep them green and beautiful. We are desert plant 
                          experts, so we will make sure your plants stay alive for years to come!
                        </div>
                      </div>
                    </div>
                  </div>}
                  {CURBING_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-curbing'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>CURBING</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                         Curbing always bring a clean look to separate the grass and rock. 
                         This a must if you are looking to have more than just grass or rock in your yard. We can style and shape the curbing to match 
                         the rest of your yard.
                        </div>
                      </div>
                    </div>
                  </div>}
                  
                  <div className="clearfix"></div>

                  {OVERHAULS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-overhauls'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>OVERHAULS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Already have a yard and just ready for something new? 
                          You have come to the right place! We can add a few features to your existing yard 
                          to spruce it up or we can tear everything out and give you a new updated yard that you have always wanted.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {ROCK_INSTALLS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-rockinstalls'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>ROCK INSTALLS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          If it's just to fill the side of a driveway or to just get rid of the plain dirt look, 
                          rock is a great way to clean up any yard quickly and fairly cheap. We can also put down a 
                          weed mat under the rock to ensure you never have to pull weeds in your new rock!
                        </div>
                      </div>
                    </div>
                  </div>}
                  {PATHWAYS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-pathways'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>PATHWAYS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Sometimes you want to just extend your patio or make a walking path. 
                          We can install paver that will give you that colorful look you are going for. We will shape it exactly how you want and 
                          blend it in perfectly with your yard.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {CLEANUPS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-cleanups'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>CLEANUPS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Sometimes life gets crazy and you neglect the yard or you have rentals that have been neglected. 
                          Let us come in and take care of that overgrown messy look and give your house a new look with a cleanup!
                        </div>
                      </div>
                    </div>
                  </div>}
                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>} 
          </Element>

          <Element name="concrete-work">
            {CONCRETE_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='servicespage__landscape-maintenance'>

              <div className='servicespage__landscape-maintenance-icon'>
                <img src={ConcreteMixer} alt='shearsicon' />
                <h1>Concrete Work</h1>
              </div>

              <div className='servicespage__landscape-maintenance-body'>
                <div className='row'>
                  {SIDEWALK_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-sidewalk'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>SIDEWALK/PATIOS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          Looking to do a little concrete work? We can give you that little extra patio length or the sidewalk down the side of your house.
                        </div>
                      </div>
                    </div>
                  </div>}
                  {STAIRS_STR.includes(this.state.currInputVal.toLowerCase()) && <div className='col-xs-12 col-sm-3'>
                    <div className='servicespage__landscape-maintenance-service'>
                      <div className='servicespage__landscape-maintenance-service-pic servicespage__landscape-maintenance-service-stairs'></div>
                      <div className='servicespage__landscape-maintenance-service-body'>
                        <div className='servicespage__landscape-maintenance-service-title'>STAIRS</div>
                        <div className='servicespage__landscape-maintenance-service-description'>
                          We can add in stone stairs in your back yard or patios. You pick the color and shape and we make it happen.
                        </div>
                      </div>
                    </div>
                  </div>}

                  <div className="clearfix"></div>
                  
                </div>
              </div>

            </div>}
          </Element>
          
        </div>
        
        <SeventhSection/>


      </div>
    )
  }

}

export default withRouter(ServicesPage);
