import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import './FirstSection.scss';

import mainLogo from 'global_images/menu-logo.png';
import fbIcon from './images/facebook.png';

import WhitePhoneButton from 'components/Buttons/WhitePhoneButton';
import GreenLinkButton from 'components/Buttons/GreenLinkButton';

import { PHONE_NUMBER_STR } from 'global_variables';


class FirstSection extends Component {

  constructor(props){
    super(props);

    this.bannerInterval = null;
    this.currImage = 1;
    this.setTimer = this.setTimer.bind(this);

  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearInterval(this.bannerInterval);
  }

  setTimer() {
    this.bannerInterval = setInterval(() => {
      if (this.currImage < 4) {
        this.currImage++;
        this.forceUpdate();
      } else {
        this.currImage = 1;
        this.forceUpdate();
      }
    }, 7000);
  }

  goDown() {  
    scroller.scrollTo('secondsection', {
      offset: -50,
      duration: 1500,
      smooth: true,
    });
  }

  goDownMobile() {
    scroller.scrollTo('secondsection', {
      offset: 100,
      duration: 1500,
      smooth: true,
    });
  }

  render() {

    const banner1Class =
      this.currImage === 1
        ? 'firstsection__background-active'
        : 'firstsection__background-inactive';
    const banner2Class =
      this.currImage === 2
        ? 'firstsection__background-active'
        : 'firstsection__background-inactive';
    const banner3Class =
      this.currImage === 3
        ? 'firstsection__background-active'
        : 'firstsection__background-inactive';
    const banner4Class =
      this.currImage === 4
        ? 'firstsection__background-active'
        : 'firstsection__background-inactive';

    return (
      <div className="firstsection">

        <div className={`firstsection__background firstsection__background-1 ${banner1Class}`}></div>
        <div className={`firstsection__background firstsection__background-2 ${banner2Class}`}></div>
        <div className={`firstsection__background firstsection__background-3 ${banner3Class}`}></div>
        <div className={`firstsection__background firstsection__background-4 ${banner4Class}`}></div>
        
        <div className="firstsection__buffer"></div>

        <div className="firstsection__mobile-logo">
          <img src={mainLogo} alt='mobile-logo'/>
        </div>

        <div className="firstsection__header"> 
          <h1>Building the landscape of your dreams.</h1>
        </div>

        <div className="firstsection__subheader"> 
          <h5>&#xb7; Proudly serving St. George area homes &#xb7;</h5>
        </div>

        <div className="firstsection__CTA"> 
          <div className='firstsection__CTA-call'>
            <WhitePhoneButton>CALL US <strong>{PHONE_NUMBER_STR}</strong></WhitePhoneButton>
          </div>

          <div className='firstsection__CTA-schedule'>
            <GreenLinkButton url='/contact#freequote'>SCHEDULE A <strong>FREE QUOTE</strong></GreenLinkButton>
          </div>
        </div>

        <div className='firstsection__bottom'>
          
           {/*<div className='firstsection__bottom-facebook'>
            <div className='firstsection__bottom-facebook-icon'>
              <a target='_blank' href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/' rel="noopener noreferrer">
                <img src={fbIcon} alt='fb'/>
              </a>
            </div>
            <div className='firstsection__bottom-facebook-review'>
              <a target='_blank' href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/' rel="noopener noreferrer">
                <span>
                  This was the first landscaping company we've come across in St. George that will work with you to figure out exactly ...
                </span>
              </a>
              <br />
              <a target='_blank' href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/' rel="noopener noreferrer">
                <div className='firstsection__bottom-facebook-review-stars'>
                  <span className='glyphicon glyphicon-star'></span>
                  <span className='glyphicon glyphicon-star'></span>
                  <span className='glyphicon glyphicon-star'></span>
                  <span className='glyphicon glyphicon-star'></span>
                  <span className='glyphicon glyphicon-star'></span>
                    <span><i>- Jennifer T.</i></span>
                </div>
              </a>
            </div>
          </div>*/}

          {/*<div className='firstsection__bottom-arrow'>
            <span className='glyphicon glyphicon-menu-down' onClick={this.goDown}/>
          </div>*/}
          
          {/*<div className='firstsection__bottom-portfolio'>
            <Link to='/gallery'>
              <div className='firstsection__bottom-portfolio-text'>VIEW OUR PORTFOLIO</div>
            </Link>
          </div>*/}

        </div>

        <Element name='secondsection'>
          <div className='firstsection__bottom-mobile'>
            <div className='firstsection__bottom-arrow'>
              <span className='glyphicon glyphicon-menu-down' onClick={this.goDownMobile}/>
            </div>
          </div>
        </Element>


      </div>
    )
  }

}

export default FirstSection;