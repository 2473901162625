import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Button.scss';

class GreenLinkButton extends Component {
  
  render() {
    return (
      <Link to={this.props.url} className='commonbutton-phone'> 
        <div className="commonbutton greenbutton">
          {this.props.children}
        </div>
      </Link>
    )
  }

}

export default GreenLinkButton;