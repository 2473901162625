import React, { Component } from 'react'
import './FourthSection.scss';

import Handshake from './images/handshake.png';
import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenLinkButton from 'components/Buttons/GreenLinkButton';

class FourthSection extends Component {

  render() {

    return (
      <div className='fourthsection'>

        <div className='fourthsection__handshake'>
          <img src={Handshake} alt='handshake-icon'/>
        </div>

        <div className='fourthsection__header'>
          <h1>You are in good hands.</h1>
        </div>

        <div className='fourthsection__subheader'>
          Did you know, the Neumann Brothers have created stunning landscaping for more than <strong>100 homes</strong> in the St. George area just in 2019?
        </div>

        <div className='fourthsection__buttons'>
          <WhiteLinkButton url='/gallery'>VIEW OUR WORK</WhiteLinkButton>
          <GreenLinkButton url='about#ourteam'>MEET OUR TEAM</GreenLinkButton>
        </div>


      </div>
    )
  }

}

export default FourthSection;