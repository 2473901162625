import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {animateScroll as scroll, scroller } from 'react-scroll';

import { PHONE_NUMBER_STR } from 'global_variables';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';

import Logo from 'global_images/menu-logo.png';


import './BlogPage.scss';



class BlogPage extends Component {

  constructor(props){
    super(props);
    this.goHome = this.goHome.bind(this);
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }


  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="blogpage">

        <div className='blogpage__banner'>
          <div className='blogpage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='blogpage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='blogpage__body'>
          <div className='blogpage__blog'>

              <div className='blogpage__blog-icon'>
                <h1>Blog coming soon!</h1>
              </div>

              <div className='blogpage__blog-subheader'>
                We plan to keep you updated on the best practices for landscaping along with useful <br /> info on 
                climate changes in St. George so that you can keep your yard in the best shape!
              </div>

          </div>

        </div>

      </div>
    )
  }

}

export default withRouter(BlogPage);