import React, { Component } from 'react'

import './Review.scss';

class Review extends Component {

  render() {

    return (
      <div className='review'>

        <a target='_blank' href={this.props.href} rel="noopener noreferrer">
          <div className='review__profile-pic'>
            <img src={this.props.img} alt='profilepic' />
          </div>
        </a>

        <a target='_blank' href={this.props.href} rel="noopener noreferrer">
          <div className='review__box'>
            <div className='review__box-content'>
              {this.props.text}
            </div>

              <div className='review__box-stars'>
                <span className='glyphicon glyphicon-star' />
                <span className='glyphicon glyphicon-star' />
                <span className='glyphicon glyphicon-star' />
                <span className='glyphicon glyphicon-star' />
                <span className='glyphicon glyphicon-star' />
              </div>

          </div>
        </a>
       
      </div>
    )
  }

}

export default Review;