import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import validator from 'validator';
import axios from 'axios';
import $script from 'scriptjs';

import './Contact.scss';

class Contact extends Component {

  constructor(props){
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      address: '',
      phone: '',
      email: '',
      notes: '',
      error: null,
      submitButtonStatus: 1, // 1 - disabled, 2 - active, 3 - loading
      captchaCode: null,
      offer1: 1,
      offer2: 1,
      offer3: 1,
      offer1email: '',
      offer2email: '',
      offer3email: '',
      offer1error: null,
      offer2error: null,
      offer3error: null,
    };

    this.submitContactInfo = this.submitContactInfo.bind(this);
    this.formIsComplete = this.formIsComplete.bind(this);
    this.captchaClicked = this.captchaClicked.bind(this);
    this.captchaExpired = this.captchaExpired.bind(this);

    this.sendRequestToServer = this.sendRequestToServer.bind(this);

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.address !== this.props.address && this.props.address !== null && this.props.address !== "") {
      this.setState({
        address: this.props.address
      })
    }

    if (prevProps.serviceArea !== this.props.serviceArea && this.props.serviceArea !== null && this.props.serviceArea !== undefined) {
      this.setState({
        notes: this.props.serviceArea ? 'Hi, I would like to schedule an estimate for my landscaping needs. Please get back to me by email or phone.' : 'Hi, I would like to know if you provide service at my location. Please get back to me by email or phone.'
      });
      
    }
  }

  componentDidMount() {
    let notes = '';
    let address = '';
    let setStateFlag = false;

    if (this.props.message) {
      notes = this.props.message;
      setStateFlag = true;
    }

    if (this.props.address) {
      address = this.props.address;
      setStateFlag = true;
    }

    if (setStateFlag) {
      this.setState({
        notes: notes,
        address: address
      });
    }

    $script('https://www.google.com/recaptcha/api.js', () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render(
          document.getElementById('my-recaptcha-placeholder'), {
            callback: this.captchaClicked, 
            sitekey: '6LehVbIUAAAAADGaJawEEzScfPlFHWM3EPiigFee',
            'expired-callback': this.captchaExpired
        });
      });
    });
  }

  async captchaClicked(code) {
    this.setState({
        submitButtonStatus: 2, 
        captchaCode: code
      });
    return true;
  }

  async captchaExpired() {
    this.setState({
      submitButtonStatus: 1, 
      captchaCode: null,
    });
    return true;
  }

  submitContactInfo() {
    // console.log(grecaptcha.reset())
    if (!this.formIsComplete()) {
        return false;
    } else {
        this.setState({
          submitButtonStatus: 3,
          error: null,
          success: null,
        }, () => {
          this.sendRequestToServer();
        });
    }
  }

  sendRequestToServer() {
    const { firstname, lastname, address, phone, email, captchaCode, notes} = this.state;

    axios.post('/api/contact', {
      firstname,
      lastname, 
      address, 
      phone,
      email, 
      notes,
      captchaCode
    })
    .then((response) => {
        window.grecaptcha.reset();
        this.setState({
          error: null,
          success: 'Thank you! You should an receive an email shortly. Please make sure to check your spam folder if you do not see it after a few minutes.',
          submitButtonStatus: 1, 
          captchaCode: null,
          firstname: '',
          lastname: '',
          address: '',
          phone: '',
          email: '',
          notes: '',
        });
    })
    .catch((error) => {
        window.grecaptcha.reset();

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.setState({
            error: error.response.data, 
            submitButtonStatus: 1, 
            success: null,
            captchaCode: null,
          });

        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({
            error: 'Could not submit form. Try again.',
            submitButtonStatus: 1,
            success: null,
            captchaCode: null,
          });
        }
    });
  }

  formIsComplete() {
    const { firstname, lastname, address, phone, email } = this.state;
    let error = null;

    if (firstname === '' || !firstname) {
      error = 'Please enter your first name.';
    } else if (lastname === '' || !lastname) {
      error = 'Please enter your last name.';
    } else if (address === '' || !address) {
      error = 'Please enter your address.';
    } else if (!validator.isMobilePhone(phone) || phone === '' || !phone) {
      error = 'Please enter your phone number.';
    } else if (!validator.isEmail(email) || email === '' || !email) {
      error = 'Please enter a real email address';
    }

    if (error) {
      this.setState({ error });
      return false;
    } 

    return true;
  }

  render() {
    return (
      <div className="contact">

        <div className="contact__body-landscape-form">
                  
          <div className="row contact__body-landscape-form-row">
            <div className="col-xs-12 col-sm-6 contact__body-landscape-form-col">
              <input
                type="text"
                id="form-first-name"
                placeholder="First Name"
                value={ this.state.firstname }
                onChange={ firstname => this.setState({ firstname: firstname.target.value }) }
              />
            </div>
            <div className="col-xs-12 col-sm-6 contact__body-landscape-form-col">
              <input
                type="text"
                id="form-last-name"
                placeholder="Last Name"
                value={ this.state.lastname }
                onChange={ lastname => this.setState({ lastname: lastname.target.value }) }
              />
            </div>
          </div>

          <div className="row contact__body-landscape-form-row">
            <div className="col-xs-12 col-sm-4 contact__body-landscape-form-col">
              <input 
                type="text" 
                id="form-address" 
                placeholder="Addess, City, State, Zipcode" 
                value={ this.state.address }
                onChange={ address => this.setState({ address: address.target.value }) }
              />
            </div>
            <div className="col-xs-12 col-sm-4 contact__body-landscape-form-col contact__body-landscape-form-phone">
              <PhoneInput
                country="US"
                placeholder="Phone"
                value={ this.state.phone }
                onChange={ phone => this.setState({ phone }) } 
              />
            </div>
            <div className="col-xs-12 col-sm-4 contact__body-landscape-form-col">
              <input
                type="text"
                id="form-email"
                placeholder="Email"
                value={ this.state.email }
                onChange={ email => this.setState({ email: email.target.value }) }
              />
            </div>
          </div>

          <div className="row contact__body-landscape-form-row">
            <div className="col-xs-12 contact__body-landscape-form-col">
               <textarea
                  type="text"
                  id="form-notes"
                  placeholder="Message (optional)"
                  value={ this.state.notes }
                  onChange={ notes => this.setState({ notes: notes.target.value }) }
                />
              </div>
          </div>

          <div className="contact__body-landscape-form-policy">
            By submitting this form, you are agreeing to the <Link to='/privacypolicy'>privacy
            policy</Link>.
          </div>

          <div id="my-recaptcha-placeholder"></div>

          {this.state.submitButtonStatus === 1 && <div className="contact-bigbutton contact-bigbutton-disabled">
            <div className="emerald-button-content">
              Contact Us
            </div>
          </div>}

          {this.state.submitButtonStatus === 2 && <div className="contact-bigbutton contact-bigbutton-enabled" onClick={this.submitContactInfo}>
            <div className="emerald-button-content">
              <span>Contact Us</span>
            </div>
          </div>}

          {this.state.submitButtonStatus === 3 && <div className="contact-bigbutton contact-bigbutton-loading">
            <div className="emerald-button-content">
              Submitting &nbsp;<span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
            </div>
          </div>}

          {this.state.error && <div className="contact__body-landscape-form-error">
            {this.state.error}
          </div>}

          {this.state.success && <div className="contact__body-landscape-form-success">
            {this.state.success}
          </div>}

                 


        </div>


      </div>
    )
  }

}

export default Contact;