import React, { Component } from 'react';
import { PHONE_NUMBER_TEL } from 'global_variables';

import './Button.scss';

class GreenPhoneButton extends Component {

  render() {
    return (
      <a href={PHONE_NUMBER_TEL} className='commonbutton-phone'> 
        <div className="commonbutton greenbutton">
          {this.props.children}
        </div>
      </a>
    )
  }

}

export default GreenPhoneButton;