import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';
import PhoneInput from 'react-phone-number-input';
import validator from 'validator';
import axios from 'axios';

import { PHONE_NUMBER_STR } from 'global_variables';

import WhiteButton from 'components/Buttons/WhiteButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';
import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenButton from 'components/Buttons/GreenButton';
import GreenButtonDisabled from 'components/Buttons/GreenButtonDisabled';
import GreenButtonLoading from 'components/Buttons/GreenButtonLoading';
import Contact from 'components/Contact';
import GoogleMap from 'components/GoogleMap';
import SpecialOffer from 'components/SpecialOffer';

import Logo from 'global_images/menu-logo.png';
import ContactIcon from './images/contact.png';
import MapIcon from './images/map.png';
import SpecialIcon from './images/special.png';
import TeamIcon from './images/teamicon.png';
import WriteIcon from './images/edit.png';
import GoogleIcon from './images/google.png';
import FacebookIcon from './images/facebook.png';
import YelpIcon from './images/yelp.png';

import './ContactPage.scss';



class ContactPage extends Component {

  constructor(props){
    super(props);

    this.goToSection = this.goToSection.bind(this);
    this.goHome = this.goHome.bind(this);

    this.showJobPosting = this.showJobPosting.bind(this);
    this.hideJobPosting = this.hideJobPosting.bind(this);

    this.showJobApply = this.showJobApply.bind(this);
    this.hideJobApply = this.hideJobApply.bind(this);

    this.goToContact = this.goToContact.bind(this);

    this.fileInputChange = this.fileInputChange.bind(this);

    this.removeResume = this.removeResume.bind(this);

    this.sendResume = this.sendResume.bind(this);

    this.changeInputName = this.changeInputName.bind(this);
    this.changeInputEmail = this.changeInputEmail.bind(this);

    this.handleResumeSuccess = this.handleResumeSuccess.bind(this);
    this.handleResumeFailure = this.handleResumeFailure.bind(this);

    this.state = {
      jobPosting: false,
      applyForJob: false,
      showContactForm: false,
      address: null,
      serviceArea: null,
      fileName: 'No resume uploaded.',
      name: '',
      email: '',
      phone: '',
      file: null,
      resumeWarning: null,
      loading: false,
      resumeSuccess: false,
      resumeFailure: false
    }
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (_.has(this.props, ['location', 'hash']) && _.has(prevProps, ['location', 'hash'])) {
      if( this.props.location !== prevProps.location ) {
        this.goToSection(this.props.location.hash.substring(1));
      }
    }
  }

  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goToContact(address, serviceArea) {
    this.goToSection('freequote');
    this.setState({
      address, 
      serviceArea,
    });
  }

  goHome() {
    this.props.history.push('/');
  }

  showJobPosting() {
    this.setState({
      jobPosting: true
    });
  }

  hideJobPosting() {
    this.setState({
      jobPosting: false,
    });
  }

  showJobApply() {
    this.setState({
      applyForJob: true
    });
  }

  hideJobApply() {
    this.setState({
      applyForJob: false,
      fileName: 'No resume uploaded.',
      name: '',
      email: '',
      phone: '',
      file: null,
      resumeWarning: null,
      loading: false,
      resumeSuccess: false,
      resumeFailure: false
    });
    this.fileInput.value = '';
  }

  fileInputChange(file) {
    if (file.target.files.length === 0) {
        this.removeResume();
    } else if (file.target.files[0].size > 1000000) {
        this.setState({
          resumeWarning: 'File too big. Keep it under 1 Mb.'
        });
    } else {
        this.setState({
          fileName: file.target.files[0].name,
          file: file.target.files[0],
          resumeWarning: null,
        });
    }
  }

  removeResume() {
    this.setState({
      file: null,
      fileName: 'No resume uploaded.',
      resumeWarning: null,
    });
    this.fileInput.value = "";
  }

  checkResumeForm() {
    if (!validator.isMobilePhone(this.state.phone)) {
        console.log(this.state.phone + ' is not a valid phone')
        this.setState({
          resumeWarning: 'Enter a valid phone number.',
        });
    } else if (!validator.isEmail(this.state.email)) {
        console.log(this.state.email + ' is not a valid email')
        this.setState({
          resumeWarning: 'Enter a valid email.',
        });
    } else {
        this.setState({
          resumeWarning: null,
        });
        return true
    }
  }


  sendResume() {
    if (this.checkResumeForm()) {

      this.setState({
        resumeSuccess: false,
        resumeFailure: false, 
        loading: true
      }, () => {
          const { name, phone, email } = this.state;
          const url = '/api/resume';
          const formData = new FormData();
          
          formData.append('file', this.state.file);
          formData.append('name', name);
          formData.append('phone', phone);
          formData.append('email', email);

          const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
          }

          axios.post(url, formData, config)
            .then((response) => {
                this.handleResumeSuccess();
            })
            .catch((error) => {
                console.log(error)
                this.handleResumeFailure();
            });
      });
      
    }
  }

  changeInputName(event) {
      this.setState({name: event.target.value});
  }

  changeInputEmail(event) {
      this.setState({email: event.target.value});
  }

  allFieldsValid() {
    if ( this.state.name !== null && this.state.name !== ''
      && this.state.email !== null && this.state.email !== ''
      && this.state.phone !== null && this.state.phone !== ''
      && this.state.file !== null && this.state.file !== undefined) {
        return true;
    }
    return false;
  }
  
  handleResumeSuccess() {
    this.setState({
      resumeSuccess: true,
      resumeFailure: false,
      loading: false,
      fileName: 'No resume uploaded.',
      name: '',
      email: '',
      phone: '',
      file: null,
      resumeWarning: null,
    });
    this.fileInput.value = '';
  }

  handleResumeFailure() {
    this.setState({
      resumeSuccess: false,
      resumeFailure: true,
      loading: false,
      fileName: 'No resume uploaded.',
      name: '',
      email: '',
      phone: '',
      file: null,
      resumeWarning: null,
    });
    this.fileInput.value = '';
  }

  render() {

    let enableSendButton = false;

    if (this.allFieldsValid()) {
        enableSendButton = true;
    }

    return (
      <div className="contactpage">

        <div className='contactpage__banner'>
          <div className='contactpage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='contactpage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='contactpage__body'>
          
          <Element name="freequote">
            <div className='contactpage__freequote'>

              <div className='contactpage__freequote-icon'>
                <img src={ContactIcon} alt='contacticon' />
                <h1>Contact Us</h1>
              </div>

              <div className='contactpage__freequote-subheader'>
                Come give us a call
              </div>

              <div className='contactpage__freequote-body'>
                <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
                <div className='contactpage__freequote-subheader'>
                ...or send us a message with any questions you have!
              </div>
                <Contact address={this.state.address} serviceArea={this.state.serviceArea}/>
              </div>
            </div>
          </Element>

          <Element name="map">
            <div className='contactpage__map'>

              <div className='contactpage__map-icon'>
                <img src={MapIcon} alt='mapicon' />
                <h1>Map</h1>
              </div>

              <div className='contactpage__map-subheader'>
                We service all of the St. George and surrounding areas. Check to see if your home is covered in our area! 
              </div>

              <div className='contactpage__map-body'>
                <GoogleMap cb={this.goToContact}/>
              </div>
            </div>
          </Element>

          <Element name="specialoffers">
            <div className='contactpage__specialoffers'>

              <div className='contactpage__specialoffers-icon'>
                <img src={SpecialIcon} alt='specialoffersicon' />
                <h1>Special Offers</h1>
              </div>

              <div className='contactpage__specialoffers-subheader'>
                Not only will we build you an beautiful back yard, but we can save you money while doing it! <br /> Join our Neumann Brothers family and sign up for any of our special offers that interest you!              </div>
              <div className='contactpage__specialoffers-body'>
                <div className='contactpage__specialoffers-body-offer'>
                  <SpecialOffer offerNumber={1} title='Free Consultation' description='The Neumann Brothers will come take a look at your landscape and figure out your exact needs.'/>
                </div>

                <div className='contactpage__specialoffers-body-offer'>
                  <SpecialOffer offerNumber={2} title='$5 off First Service' description='The Neumann Brothers will take $5 off the first maintenance service. Valid anytime.'/>
                </div>

                <div className='contactpage__specialoffers-body-offer'>
                  <SpecialOffer offerNumber={3} title='$20 off Yearly Cleanup' description='The Neumann Brothers will take $20 off your yearly cleanup. Valid once per year.'/>
                </div>
              </div>
            </div>
          </Element>

          {this.state.jobPosting && <div className='contactpage__joinus-description'>
            <div className='contactpage__joinus-description-closebtn'>
              <span className='glyphicon glyphicon-remove' onClick={this.hideJobPosting}></span>
            </div>
            <h2>Landscaping Crew Member</h2>
            <h4>Neumann Brothers Landscape</h4><h5>St. George, UT</h5>

            <div className='contactpage__joinus-description-status'> 
              <span className='glyphicon glyphicon-time'></span>
              <div>Open position. Currently accepting applicants.</div>
            </div>

            <div className='contactpage__joinus-description-status-requirements'>
              What we are looking for:
            </div>

            <div className='contactpage__joinus-description-status-requirements-list'>
              <ul>
                <li>Mowing</li>
                <li>Edging</li>
                <li>Yard cleanup</li>
                <li>Bush and tree trimming</li>
                <li>Shoveling</li>
                <li>Moving dirt, rock, sod</li>
              </ul>
            </div>

            <div className='contactpage__joinus-description-status-requirements'>
              Our schedule 
            </div>

            <div className='contactpage__joinus-description-status-requirements-list'>
              <ul>
                <li>9am - 5pm</li>
                <li>30 - 40hrs a week</li>
                <li>Monday - Friday with the occasional Saturday</li>
              </ul>
            </div>
          </div>}

          {this.state.applyForJob && <div className='contactpage__joinus-apply'>
            <div className='contactpage__joinus-apply-closebtn'>
              <span className='glyphicon glyphicon-remove' onClick={this.hideJobApply}></span>
            </div>
            <h2>Landscaping Crew Member Application</h2>
            <h4>Neumann Brothers Landscape</h4><h5>St. George, UT</h5>

            <div className='contactpage__joinus-apply-name'>
              <input value={this.state.name} type='text' placeholder='Name' onChange={this.changeInputName}/>
            </div>

            <div className='contactpage__joinus-apply-email'>
              <input value={this.state.email} type='text' placeholder='Email' onChange={this.changeInputEmail}/>
            </div>

            <div className='contactpage__joinus-apply-phone'>
              <PhoneInput
                country="US"
                placeholder="Phone"
                value={ this.state.phone }
                onChange={ phone => this.setState({ phone }) } 
              />
            </div>

            <div className='contactpage__joinus-apply-email'>
              
              { !(this.state.resumeSuccess || this.state.resumeFailure) && <div className='contactpage__joinus-apply-email-description'>
                Upload your resume in .doc, .docx, or PDF format. Keep the file under 1 Mb.
              </div>}

              { !(this.state.resumeSuccess || this.state.resumeFailure) && !this.state.file && <WhiteButton cb={ () => this.fileInput.click() }>Upload Resume</WhiteButton> }
              
              { !(this.state.resumeSuccess || this.state.resumeFailure) && <div className='contactpage__joinus-apply-resume-description'>
                <span className='glyphicon glyphicon-file'/>{this.state.fileName}
                {this.state.file && <span onClick={this.removeResume} className='glyphicon glyphicon-remove contactpage__joinus-apply-remove-doc'></span>}
              </div> }

              <div className='contactpage__joinus-apply-resume-error'>{ this.state.resumeWarning }</div>
              <div className='contactpage__joinus-apply-divider'></div>
              
              {!enableSendButton && !this.state.loading && !this.state.resumeSuccess && !this.state.resumeFailure && <GreenButtonDisabled>Send</GreenButtonDisabled>}
              {enableSendButton && !this.state.loading && !this.state.resumeSuccess && !this.state.resumeFailure && <GreenButton cb={this.sendResume}>Send</GreenButton>}
              {this.state.loading && <GreenButtonLoading>Sending...</GreenButtonLoading>}
              {this.state.resumeSuccess && <div className='contactpage__joinus-apply-success'>Resume submitted!<br />Please check your email for confirmation.</div>}
              {this.state.resumeFailure && <div className='contactpage__joinus-apply-faliure'>Something went wrong on our side. We are really sorry about this. Please email your resume directly to <a target='_blank' href="mailto:craig@neumannbrotherslandscape.com" rel="noopener noreferrer">craig@neumannbrotherslandscape.com.</a></div>}
              { (this.state.resumeFailure || this.state.resumeSuccess) && <div className='contactpage__joinus-apply-closetext' onClick={this.hideJobApply}>Close</div>}

              <input accept=".doc,.docx,.pdf" type='file' onChange={this.fileInputChange} name='resume' ref={fileInput => this.fileInput = fileInput} />
            </div>


          </div>}
          
          <Element name="comejoinus">
            <div className='contactpage__joinus'>

              <div className='contactpage__joinus-icon'>
                <img src={TeamIcon} alt='teamicon' />
                <h1>Join Our Team</h1>
              </div>

              <div className='contactpage__joinus-subheader'>
                We are a small company but growing fast! We believe in good quality work and great customer service. 
                We are looking for someone who has a good work ethic and enjoys doing quality work. We are looking 
                for people that want than just more out of life than a 9 to 5.<br /><br />Someone that isnt afraid to work hard 
                  and is looking to grow within a company. There is a lot of opportunity for growth and good money 
                for the right people. If this sounds like you let us know!
              </div>
              <div className='contactpage__joinus-body'>
                <div  className='contactpage__joinus-body-buttons'>
                  <WhiteButton cb={this.showJobPosting}>Learn More</WhiteButton>
                  <GreenButton cb={this.showJobApply}>Sign Me Up! </GreenButton>
                </div>
              </div>
            </div>
          </Element>

          <Element name="writeareview">
            <div className='contactpage__writeareview'>

              <div className='contactpage__writeareview-icon'>
                <img src={WriteIcon} alt='writeicon' />
                <h1>Write a Review</h1>
              </div>

              <div className='contactpage__writeareview-subheader'>
                We appreciate the opportunity to be your one-stop-shop for all your landscaping needs. To help us continue the highest quality of service, we invite you to leave feedback on any (or all!) of the platforms below. 
                Thank you for your time and we hope we see you again soon!
              </div>
              
              <div className='contactpage__writeareview-body'>
                <div className='contactpage__writeareview-btn'>
                  <a target='_blank' href='https://www.google.com/search?q=Neumann+Brothers+Landscape&ludocid=10013095038587562179&lsig=AB86z5WH23pBFS22EqYiWVqHP25a#fpstate=lie&lrd=0x80ca51b8bf2bb0cf:0x8af5a8e2cd3ce8c3,1,,,' rel="noopener noreferrer"><img src={GoogleIcon} alt='googleicon' /></a>
                </div>
                <div className='contactpage__writeareview-btn'>
                  <a target='_blank' href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/?ref=page_internal' rel="noopener noreferrer"><img src={FacebookIcon} alt='facebookicon' /></a>
                </div>
                <div className='contactpage__writeareview-btn'>
                  <a target='_blank' href='https://www.yelp.com/biz/neumann-brothers-landscape-st-george' rel="noopener noreferrer"><img src={YelpIcon} alt='yelpicon' /></a>
                </div>
              </div>
            </div>
          </Element>


        </div>


      </div>
    )
  }

}

export default withRouter(ContactPage);
