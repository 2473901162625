import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { animateScroll as scroll, scroller } from 'react-scroll';

import { PHONE_NUMBER_STR } from 'global_variables';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';

import Logo from 'global_images/menu-logo.png';

import './PrivacyPolicy.scss';



class AboutPage extends Component {

  constructor(props){
    super(props);

    this.goToSection = this.goToSection.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (_.has(this.props, ['location', 'hash']) && _.has(prevProps, ['location', 'hash'])) {
      if( this.props.location !== prevProps.location ) {
        this.goToSection(this.props.location.hash.substring(1));
      }
    }
  }

  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="privacypage">

        <div className='privacypage__banner'>
          <div className='privacypage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='aboutpage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='privacypage__body'>
          
        <h1><strong>Welcome to our Privacy Policy</strong></h1>
        <h3>Your privacy is critically important to us.</h3>
        <strong>Neumann Brothers Landscape is located in:</strong>
        <address><strong>St. George, UT</strong></address>

      <p>It is Neumann Brothers Landscape's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://www.neumannbrotherslandscape.com/">https://www.neumannbrotherslandscape.com/</a> (hereinafter, "us", "we", or "https://www.neumannbrotherslandscape.com/"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
      <p>This Privacy Policy sets forth the general rules and policies governing your use of our Website. </p>

            <h2><strong>Website Visitors</strong></h2>
      <p>Like most website operators, Neumann Brothers Landscape collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Neumann Brothers Landscape's purpose in collecting non-personally identifying information is to better understand how Neumann Brothers Landscape's visitors use its website. From time to time, Neumann Brothers Landscape may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
      
            <h2><strong>Gathering of Personally-Identifying Information</strong></h2>
      <p>Certain visitors to Neumann Brothers Landscape's websites choose to interact with Neumann Brothers Landscape in ways that require Neumann Brothers Landscape to gather personally-identifying information. The amount and type of information that Neumann Brothers Landscape gathers depends on the nature of the interaction. For example, we ask visitors who contact us via the website to provide their name, phone, home addrss, and email address.</p>
      
            <h2><strong>Security</strong></h2>
      <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
      
      

            <h2><strong>Links To External Sites</strong></h2>
      <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
      <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
      
      
      
            <h2><strong>Aggregated Statistics</strong></h2>
      <p>Neumann Brothers Landscape may collect statistics about the behavior of visitors to its website. Neumann Brothers Landscape may display this information publicly or provide it to others. However, Neumann Brothers Landscape does not disclose your personally-identifying information.</p>
      
      
            <h2><strong>Cookies</strong></h2>
      <p>To enrich and perfect your online experience, Neumann Brothers Landscape uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
      <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Neumann Brothers Landscape uses cookies to help Neumann Brothers Landscape identify and track visitors, their usage of https://www.neumannbrotherslandscape.com/, and their website access preferences. Neumann Brothers Landscape visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Neumann Brothers Landscape's websites, with the drawback that certain features of Neumann Brothers Landscape's websites may not function properly without the aid of cookies.</p>
      <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Neumann Brothers Landscape's use of cookies.</p>
      
      
      
            <h2><strong>Privacy Policy Changes</strong></h2>
      <p>Although most changes are likely to be minor, Neumann Brothers Landscape may change its Privacy Policy from time to time, and in Neumann Brothers Landscape's sole discretion. Neumann Brothers Landscape encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
      
      
              
        <h2><strong>Google Analytics</strong></h2>       
          <p>Google Analytics is used on this website to collect data on what device the user is accessing the webpage on and what website or app [if any] the user came from. Google Analytics also collects data on the amount of time spent on the website along with the user's navigation throughout the website. Google Analytics does all of these things by storing cookies on the user's browser. </p>             
        
        <h2><strong>Google Maps API</strong></h2>        
          <p>Google Maps API is used on this website to allow the user to check if his/her address is within the area of service. The user must type in the user's address manually. We do not use GPS to collect user's location information. We also do not collect the address of the user for simply just checking if the user's address is in the area of service. The only way we will collect this information is if the user intentionally clicks on a Call to Action button afterwards that allows the user to contact us via a form with the user's name, address, email, phone, and a custom message (optional). </p>              
       
        <h2><strong>Contact Information</strong></h2>        
          <p>We use a contact box on the website to gather information about the user's name, email, phone, and address if the user wants to contact us. The user has to initiate the process as we do not gather this information automatically unless the user types it intentionally and sends it. We collect this information so that one of our representatives can respond to the user's inquiry. We also store this information so that we may access it at a later time to contact the user if needed. We do not share or sell this information with any 3rd party. This information is stored securely. </p>     
      
      <h2><strong>Credit & Contact Information</strong></h2>
              <p>This privacy policy was created at <a href="https://termsandconditionstemplate.com/privacy-policy-generator/" rel="noopener noreferrer" title="Privacy policy template generator" target="_blank">termsandconditionstemplate.com</a>. If you have any questions about this Privacy Policy, please contact us via <a href="mailto:craig@neumannbrotherslandscape.com">email</a> or <a href="tel:4358621885">phone</a>.</p>
          
              <br />
              <br />

        </div>

      </div>
    )
  }

}

export default withRouter(AboutPage);