import React, { Component } from 'react'

import './Rating.scss';

import YelpLogo from './images/yelp.png'
import FacebookLogo from './images/facebook.png'
import GoogleLogo from './images/google.png'

class YelpRating extends Component {

  render() {

    return (

      <div className='ratingparent'>

        {this.props.rating === 'yelp' && <div className='yelprating rating'>
          <div className='rating__circle yelprating__circle'>
            <div className='rating__circle-rating yelprating__circle-rating'>
              <sup>4.9</sup><div className='rating__circle-rating-slash yelprating__circle-rating-slash'></div><sub>5</sub>
            </div>
          </div>
          <div className='rating__logo yelprating__logo'>
            <img src={YelpLogo} alt='yelplogo' />
          </div>
          <div className='rating__link'>
            <a target='_blank' href='https://www.yelp.com/biz/neumann-brothers-landscape-st-george' rel="noopener noreferrer">10+ reviews</a>
          </div>
        </div>}

        {this.props.rating === 'facebook' && <div className='facebookrating rating'>
          <div className='rating__circle facebookrating__circle'>
            <div className='rating__circle-rating facebookrating__circle-rating'>
              <sup>5</sup><div className='rating__circle-rating-slash facebookrating__circle-rating-slash'></div><sub>5</sub>
            </div>
          </div>
          <div className='rating__logo facebookrating__logo'>
            <img src={FacebookLogo} alt='facebooklogo' />
          </div>
          <div className='rating__link'>
            <a target='_blank' href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/?ref=page_internal' rel="noopener noreferrer">15+ reviews</a>
          </div>
        </div>}

        {this.props.rating === 'google' && <div className='googlerating rating'>
          <div className='rating__circle googlerating__circle'>
            <div className='rating__circle-rating googlerating__circle-rating'>
              <sup>4.9</sup><div className='rating__circle-rating-slash googlerating__circle-rating-slash'></div><sub>5</sub>
            </div>
          </div>
          <div className='rating__logo googlerating__logo'>
            <img src={GoogleLogo} alt='googlelogo' />
          </div>
          <div className='rating__link'>
           <a target='_blank' href='https://www.google.com/maps?cid=10013095038587562179' rel="noopener noreferrer">5+ reviews</a>
          </div>
        </div>}



      </div>
    )
  }

}

export default YelpRating;