import React, { Component } from 'react'
import Creatable from 'react-select/creatable';

import { PHONE_NUMBER_STR } from 'global_variables';

import './SecondSection.scss';

import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';
import GreenLinkButton from 'components/Buttons/GreenLinkButton';
import WhiteButton from 'components/Buttons/WhiteButton';
import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import Contact from 'components/Contact';

import Calendar from 'global_images/calendar-icon.png';
import Wheelbarrow from 'global_images/wheelbarrow-icon.png';
import Sprinkler from 'global_images/sprinkler-icon.png';
import Shears from 'global_images/shears-icon.png';
import Bush from 'global_images/bush-icon.png';
import ConcreteMixer from 'global_images/concrete-mixer-icon.png';

const options = [
  { value: '/services#landscape-maintenance', label: 'Lawn mowing' },
  { value: '/services#landscape-maintenance', label: 'Edging' },
  { value: '/services#landscape-maintenance ', label: 'Bush trimming' },
  { value: '/services#landscape-maintenance', label: 'Pruning' },
  { value: '/services#landscape-maintenance', label: 'Tree trimming' },
  { value: '/services#landscape-maintenance', label: 'Weeding' },
  { value: '/services#areation-fertilization', label: 'Areation' },
  { value: '/services#areation-fertilization', label: 'Fertilization' },
  { value: '/services#sprinkler-valve-repair', label: 'Sprinkler system installation' },
  { value: '/services#sprinkler-valve-repair', label: 'Sprinkler head change' },
  { value: '/services#sprinkler-valve-repair', label: 'Sprinkler valve repair' },
  { value: '/services#sprinkler-valve-repair', label: 'Leaking sprinkler valves' },
  { value: '/services#sprinkler-valve-repair', label: 'Drip line installation' },
  { value: '/services#palm-tree-trimming', label: 'Palm tree trimming' },
  { value: '/services#palm-tree-trimming', label: 'Palm tree removal' },
  { value: '/services#palm-tree-trimming', label: 'Palm tree installation' },
  { value: '/services#landscape-installs-and-overhauls', label: '3D designed front and back yards' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Tree installation' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Bush installation' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Grass installation' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Grass removal' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Turf installation' },
  { value: '/services#concrete-work', label: 'Curbing' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Landscape overhaul' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Rock installation' },
  { value: '/services#concrete-work', label: 'Pathways' },
  { value: '/services#landscape-installs-and-overhauls', label: 'Cleanups' },
  { value: '/services#concrete-work', label: 'Sidewalk installation' },
  { value: '/services#concrete-work', label: 'Pavement installation' },
  { value: '/services#concrete-work', label: 'Stair installation' },
  { value: '/services#concrete-work', label: 'Patio installation' },
];

class SecondSection extends Component {

  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.closeContactForm = this.closeContactForm.bind(this);

    this.state = {
       selectedOption: null, 
       showContactForm: false,
       newOption: null,
       currURL: '/',
    }

  }

  handleChange(selectedOption) {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    if ( '__isNew__' in selectedOption) {
      this.setState({ 
        showContactForm: true, 
        newOption: selectedOption.label
      });
    } else {
      this.setState({
        currURL: selectedOption.value
      });
    }
  }

  newInput(newOption) {
    return `Couldn't find what you were looking for? Click here to ask us about "${newOption}"`
  }

  checkNewInput(inputVal, selectVal, options) {
    for(let i = 0; i < options.length; i++) {
      if ( options[i].label.toLowerCase().includes(inputVal) ) {
        return false;
      }
    }

    return true;
  }

  closeContactForm() {
    this.setState({ 
      showContactForm: false, 
      selectedOption: null, 
      newOption: null,
      currURL: '/',
    });
  }



  render() {
    const { selectedOption, currURL } = this.state;

    return (
      <div className="secondsection">
      
        <div className="secondsection__subheader">
          OVER <i>20 YEARS</i> OF PRACTICE 
        </div>

        <div className="secondsection__header">
          <h1>We deliver the landscape exactly how <i>you</i> want it.</h1>
        </div>

        <div className="secondsection__dropdown">
          <Creatable
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            placeholder='What service do you need?'
            formatCreateLabel={ this.newInput }
            isValidNewOption={ this.checkNewInput }
            className="secondsection__dropdown-select"
            classNamePrefix="secondsection__dropdown-select"
          />
          <div className='secondsection__dropdown-button'>
            <GreenLinkButton url={currURL}><span className='glyphicon glyphicon-search'></span></GreenLinkButton>
          </div>
        </div>


        <div className='secondsection__space'>
        </div>

        {this.state.showContactForm && <div className='secondsection__contact'>

          <div className='secondsection__contact-close'>
            <span className='glyphicon glyphicon-remove' onClick={this.closeContactForm}></span>
          </div>

          <h3>Want an answer right away about {this.state.newOption}?</h3>

          <GreenPhoneButton>CALL NOW <strong>{PHONE_NUMBER_STR}</strong></GreenPhoneButton>
          
          <div className='secondsection__contact-break'></div>

          <h3>Want us to get in touch with you?</h3>

          <Contact message={`Hi, I would like to know if you provide ${this.state.newOption}.`} />

          <WhiteButton cb={this.closeContactForm}>Close</WhiteButton>
        </div>}


        <div className="secondsection__services">
          <div className='row'>
            
            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-maintenance'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={Calendar} alt='calendar' />
                    <div className='secondsection__services-bubble-cover-title'>Landscape<br />Maintenance</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; Mowing</div>
                      <div>&#183; Edging</div>
                      <div>&#183; Weed maintenance</div>
                    </div>
                    <WhiteLinkButton url='/services#landscape-maintenance'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-fertilization'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={Wheelbarrow} alt='wheelbarrow' />
                    <div className='secondsection__services-bubble-cover-title'>AERATION/<br />FERTILIZATION</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; Aeration</div>
                      <div>&#183; Fertilization</div>
                    </div>
                    <WhiteLinkButton url='/services#areation-fertilization'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-sprinkler'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={Sprinkler} alt='sprinkler' />
                    <div className='secondsection__services-bubble-cover-title'>SPRINKLER/VALVE<br />REPAIR</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; Sprinkler repair/maintenance</div>
                      <div>&#183; Valve repair</div>
                      <div>&#183; Sprinkler/dripline installs</div>
                    </div>
                    <WhiteLinkButton url='/services#sprinkler-valve-repair'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-palmtree'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={Shears} alt='shears' />
                    <div className='secondsection__services-bubble-cover-title'>PALM TREE<br />TRIMMING</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; Palm leave pruning</div>
                      <div>&#183; Removal of dead or unhealthy fronds</div>
                      <div>&#183; Small palm tree removals/installs</div>
                    </div>
                    <WhiteLinkButton url='/services#palm-tree-trimming'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-landscape'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={Bush} alt='bush' />
                    <div className='secondsection__services-bubble-cover-title'>LANDSCAPE INSTALLS<br />& OVERHAULS</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; 3D design mockup of new yards</div>
                      <div>&#183; New yard installs</div>
                      <div>&#183; Existing yard removals and remodels</div>
                    </div>
                    <WhiteLinkButton url='/services#landscape-installs-and-overhauls'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-lg-4'> 
              <div className='secondsection__services-bubble secondsection__services-bubble-concrete'>
                <div className='secondsection__services-bubble-wrapper'>
                  <div className='secondsection__services-bubble-cover'>
                    <img src={ConcreteMixer} alt='concrete-mixer' />
                    <div className='secondsection__services-bubble-cover-title'>CONCRETE WORK</div>
                  </div>
                  <div className='secondsection__services-bubble-info'>
                    <div className='secondsection__services-bubble-info-text'>
                      <div>&#183; Curbing</div>
                      <div>&#183; Sidewalks</div>
                      <div>&#183; Patios</div>
                    </div>
                    <WhiteLinkButton url='/services#concrete-work'>READ MORE</WhiteLinkButton>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default SecondSection;