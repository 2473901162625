import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import './DesktopMenu.scss';

import mainLogo from 'global_images/menu-logo.png';
import GreenButton from 'components/Buttons/GreenButton.jsx';

import { PHONE_NUMBER_STR, PHONE_NUMBER_TEL } from 'global_variables';

class DesktopMenu extends Component {

  constructor(props){
    super(props);

    this.state = {
      headerBackground: false,
      goof: 0,
    };

    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.goHome = this.goHome.bind(this);
    this.goToFreeQuote = this.goToFreeQuote.bind(this);

    // This increments the key in the menu everything something is clicked.
    // This forces the menu rerender which closes it.
    this.goofThing = this.goofThing.bind(this);

    this.getPath = this.getPath.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  componentDidUpdate(prevProps) {

  }


  listenScrollEvent(e) {
    if (window.scrollY > 60) {
      this.setState({ headerBackground: true });
    } else {
      this.setState({ headerBackground: false });
    }
  }

  goHome() {
      this.props.history.push('/');
  }

  goToFreeQuote() {
    this.props.history.push('/contact#freequote');
  }

  goofThing() {
    this.setState({
      goof: this.state.goof + 1,
    });
  }

  getPath() {
    if ( 'location' in this.props ) {
      return this.props.location.pathname.substring(1);
    } 
    return false;
  }

  render() {

    let headerClassname = this.state.headerBackground ? "desktopmenu desktopmenu-background" : "desktopmenu";

    let pathname = this.getPath();

    let aboutClass = pathname === 'about' ? 'main-nav-li-highlighted' :  '';
    let servicesClass = pathname === 'services' ? 'main-nav-li-highlighted' :  '';
    let galleryClass = pathname === 'gallery' ? 'main-nav-li-highlighted' :  '';;
    let blogClass = pathname === 'blog' ? 'main-nav-li-highlighted' :  '';
    let contactClass = pathname === 'contact' ? 'main-nav-li-highlighted' :  '';


    return (
      <div key={this.state.goof} className={headerClassname}>

        <div id="main-nav-2">
          <div id="main-nav-2-phone">
            <span className="glyphicon glyphicon-earphone" aria-hidden="true"></span>
            <a href={PHONE_NUMBER_TEL} id="main-nav-2-phone-text">{PHONE_NUMBER_STR}</a>
          </div>
          <div id="main-nav-2-menu">
          {this.props.open && <span onClick={this.props.handleSideMenuClose} className="glyphicon glyphicon-remove" aria-hidden="true"></span>}
          {!this.props.open && <span onClick={this.props.handleSideMenuOpen} className="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>}
          </div>


        </div>

        <nav id="main-nav">
          <ul>

            <li className="main-nav-li">
              <Link to="/about" className='main-nav-li-main'>
                <span className={aboutClass}>ABOUT</span>
                <span className="arrow">&#x25BC;</span>
              </Link>
              <ul className="submenu">
                <li>
                  <Link to="/about#ourteam" onClick={ this.goofThing }>OUR TEAM</Link>
                </li>
                <li>
                  <Link to="/about#whyus" onClick={ this.goofThing }>WHY US?</Link>
                </li>
                <li>
                  <Link to="/about#ourstory" onClick={ this.goofThing }>OUR STORY</Link>
                </li>
                <li>
                  <Link to="/about#reviews" onClick={ this.goofThing }>REVIEWS</Link>
                </li>
                <li>
                  <Link to="/about#faq" onClick={ this.goofThing }>FAQ</Link>
                </li>
                <li>
                  <Link to="/about#calculator" onClick={ this.goofThing }>LANDSCAPE CALCULATOR</Link>
                </li>
              </ul>
            </li>

            <li className="main-nav-li">
              <Link to="/services" className='main-nav-li-main'>
                 <span className={servicesClass}>SERVICES</span>
                <span className="arrow">&#x25BC;</span>
              </Link>
              <ul className="submenu">
                <li>
                  <Link to="/services#landscape-maintenance" onClick={ this.goofThing }>LANDSCAPE MAINTENANCE</Link>
                </li>
                <li>
                  <Link to="/services#areation-fertilization" onClick={ this.goofThing }>AREATION/FERTILIZATION</Link>
                </li>
                <li>
                  <Link to="/services#sprinkler-valve-repair" onClick={ this.goofThing }>SPRINKLER/VALVE REPAIR</Link>
                </li>
                <li>
                  <Link to="/services#palm-tree-trimming" onClick={ this.goofThing }>PALM TREE TRIMMING</Link>
                </li>
                <li>
                  <Link to="/services#landscape-installs-and-overhauls" onClick={ this.goofThing }>LANDSCAPE INSTALLS AND OVERHAULS</Link>
                </li>
                <li>
                  <Link to="/services#concrete-work" onClick={ this.goofThing }>CONCRETE WORK</Link>
                </li>
              </ul>
            </li>

            <li className="main-nav-li">
              <Link to="/gallery" className='main-nav-li-main'>
                <span className={galleryClass}>GALLERY</span>
                <span className="arrow">&#x25BC;</span>
              </Link>
              <ul className="submenu">
                <li>
                  <Link to="/gallery#rock-install" onClick={ this.goofThing }>ROCK INSTALL</Link>
                </li>
                <li>
                  <Link to="/gallery#putting-green" onClick={ this.goofThing }>PUTTING GREEN</Link>
                </li>
                <li>
                  <Link to="/gallery#cleanups" onClick={ this.goofThing }>CLEANUPS</Link>
                </li>
                <li>
                  <Link to="/gallery#front-yard-redesign" onClick={ this.goofThing }>FRONT YARD REDESIGN</Link>
                </li>
                <li>
                  <Link to="/gallery#drain-and-rocks" onClick={ this.goofThing }>DRAIN AND ROCK</Link>
                </li>
                <li>
                  <Link to="/gallery#leak-fix" onClick={ this.goofThing }>LEAK FIX</Link>
                </li>
                <li>
                  <Link to="/gallery#grass-removal" onClick={ this.goofThing }>GRASS REMOVAL</Link>
                </li>
                <li>
                  <Link to="/gallery#concrete" onClick={ this.goofThing }>CONCRETE</Link>
                </li>
              </ul>
            </li>
            
             <li className="main-nav-li">
               <div className='desktopmenu__logo'>
                 <img src={mainLogo} alt='logo' onClick={this.goHome}/>
               </div>
            </li>

            <li className="main-nav-li">
               <Link to="/blog" className='main-nav-li-main'>
                  <span className={blogClass}>BLOG</span>
               </Link>
            </li>

            <li className="main-nav-li">
              <Link to="/contact" className='main-nav-li-main'>
                 <span className={contactClass}>CONTACT</span>
                 <span className="arrow">&#x25BC;</span>
              </Link>
               <ul className="submenu">
                <li>
                  <Link to="/contact#freequote" onClick={ this.goofThing }>FREE QUOTE</Link>
                </li>
                <li>
                  <Link to="/contact#map" onClick={ this.goofThing }>MAP</Link>
                </li>
                <li>
                  <Link to="/contact#specialoffers" onClick={ this.goofThing }>SPECIAL OFFERS</Link>
                </li>
                <li>
                  <Link to="/contact#comejoinus" onClick={ this.goofThing }>COME JOIN OUR TEAM</Link>
                </li>
                <li>
                  <Link to="/contact#writeareview" onClick={ this.goofThing }>WRITE A REVIEW</Link>
                </li>
              </ul>
            </li>

            <li className="main-nav-li">
              <GreenButton cb={this.goToFreeQuote}>
                FREE QUOTE
              </GreenButton>
            </li>
          </ul>
        </nav>
        
        <div className='desktopmenu__trapezoid-div'>
          <div className='desktopmenu__trapezoid'></div>
        </div>

      </div>
    )
  }

}
export default withRouter(DesktopMenu);