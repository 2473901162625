import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';

import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DesktopMenu';
import HomePage from 'components/HomePage';
import AboutPage from 'components/AboutPage';
import ServicesPage from 'components/ServicesPage';
import GalleryPage from 'components/GalleryPage';
import BlogPage from 'components/BlogPage';
import ContactPage from 'components/ContactPage';
import PrivacyPolicy from 'components/PrivacyPolicy';
import Footer from 'components/Footer';


class AppRouter extends Component {

  constructor(props){
    super(props);

    this.state = {
      open: false
    }

    this.handleSideMenuOpen = this.handleSideMenuOpen.bind(this);
    this.handleSideMenuClose = this.handleSideMenuClose.bind(this);
    
  }

  handleSideMenuOpen() {
    this.setState( { open: true });
  }

  handleSideMenuClose() {
    this.setState( { open: false });
  }

  render() {

    return (
      <Router>
        <MobileMenu handleSideMenuOpen={this.handleSideMenuOpen} handleSideMenuClose={this.handleSideMenuClose} open={this.state.open} />
        <DesktopMenu handleSideMenuOpen={this.handleSideMenuOpen} handleSideMenuClose={this.handleSideMenuClose} open={this.state.open} />

        <Switch>
          <Route exact path="/" render={(props) => <HomePage />} />
          <Route path="/about" render={(props) => <AboutPage />} />
          <Route path="/services" render={(props) => <ServicesPage />} />
          <Route path="/gallery" render={(props) => <GalleryPage />} />
          <Route path="/blog" render={(props) => <BlogPage />} />
          <Route path="/contact" render={(props) => <ContactPage />} />
          <Route path="/privacypolicy" render={(props) => <PrivacyPolicy />} />


          <Route path=""render={(props) => <HomePage />} />
        </Switch>

        <Footer />
      </Router>
    );
  }
}

export default AppRouter;