import React, { Component } from 'react';

import { PHONE_NUMBER_STR } from 'global_variables';

import './SeventhSection.scss';

import WhitePhoneButton from 'components/Buttons/WhitePhoneButton';
import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenLinkButton from 'components/Buttons/GreenLinkButton';

import Tree from './images/tree.png'

class SeventhSection extends Component {

  render() {
    return (
      <div className="seventhsection">

        <div className='seventhsection__tree'>
          <img src={Tree} alt='treeicon'/>
        </div>

        <div className='seventhsection__header'>
          <h1>Are you ready to enjoy your finished yard with your friends and family?</h1>
        </div>

        <div className='seventhsection__buttons'>
          <WhitePhoneButton>CALL US {PHONE_NUMBER_STR}</WhitePhoneButton>
          <GreenLinkButton url='/contact#freequote'>SCHEDULE A FREE QUOTE</GreenLinkButton>
        </div>

        <div className='seventhsection__subheader'>
          Feel free to check out our landscaping calculator to help you get started.
        </div>

        <div className='seventhsection__calcbutton'>
          <WhiteLinkButton url='/about#calculator'>LANDSCAPING CALCULATOR</WhiteLinkButton>
        </div>

      </div>
    )
  }

}

export default SeventhSection;