import React, { Component } from 'react'

import './FirstHalfSection.scss';

import RenderVideo from './images/render.m4v';
import RealVideo from './images/real.m4v';
// import Maintenance from 'global_images/services/landscape-old.jpg'
import Maintenance from './images/maintain.jpeg'

class FirstHalfSection extends Component {

  constructor(props) {
    super(props);

    this.playPromise = null;
    this.playRealPromise = null;

    this.playRenderVideo = this.playRenderVideo.bind(this);
    this.pauseRenderVideo = this.pauseRenderVideo.bind(this);

    this.playRealVideo = this.playRealVideo.bind(this);
    this.pauseRealVideo = this.pauseRealVideo.bind(this);
  }

  playRenderVideo() {
    // console.log('play')
    this.playPromise = this.refs.renderVidRef.play();
    if (this.playPromise !== undefined) {
      this.playPromise.then(() => {
      })
      .catch(error => {
        console.log('error playing render video', error);
      });
    }
  }

  pauseRenderVideo() {
    // console.log('Attempting to pause video');
    if (this.playPromise !== undefined) {
      this.playPromise.then(() => {
        // console.log('Paused video.');
        this.refs.renderVidRef.pause();
      })
      .catch(error => {
        console.log('error pausing render video', error);
      });
    }
  }

  playRealVideo() {
    // console.log('play')
    this.playRealPromise = this.refs.realVidRef.play();
    if (this.playRealPromise !== undefined) {
      this.playRealPromise.then(() => {
      })
      .catch(error => {
        console.log('error playing real video', error);
      });
    }
  }

  pauseRealVideo() {
    // console.log('Attempting to pause video');
    if (this.playRealPromise !== undefined) {
      this.playRealPromise.then(() => {
        // console.log('Paused video.');
        this.refs.realVidRef.pause();
      })
      .catch(error => {
        console.log('error pausing real video', error);
      });
    }
  }

  render() {
    return (
      <div className='firsthalfsection'>

        <div className='firsthalfsection__header'>
          <h1>And it's as easy as 1, 2, 3.</h1>
        </div>

        <div className='firsthalfsection__wrapper'>
          <div className='row'>
            
            <div className='col-sm-4 col-xs-12 firsthalfsection-col-1'>
              <div className='firsthalfsection__box-outer firsthalfsection__box-outer-1'>
                <div className='firsthalfsection__box-circle'>
                  <h1>1</h1>
                </div>

                <div className='firsthalfsection__box-inner firsthalfsection__box-inner-1'>
                  <video muted loop ref="renderVidRef">
                    <source src={RenderVideo} type="video/mp4"/>
                  </video>

                  <div className='firsthalfsection__box-overlay' onMouseOver={this.playRenderVideo} onMouseLeave={this.pauseRenderVideo}>
                    <div className='firsthalfsection__box-overlay-header'>
                      <h1>PLAN</h1>
                      <h5>We create extremely accurate 3D models of your vision so you can see exactly what you're getting.</h5>
                      <a href='https://www.youtube.com/watch?v=RkmpKMUN1GU' target="_blank" >See whole video.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-sm-4 col-xs-12 firsthalfsection-col-2'>
             <div className='firsthalfsection__box-outer firsthalfsection__box-outer-2'>
                <div className='firsthalfsection__box-circle'>
                  <h1>2</h1>
                </div>

                <div className='firsthalfsection__box-inner firsthalfsection__box-inner-2'>
                  <video muted loop ref="realVidRef">
                    <source src={RealVideo} type="video/mp4"/>
                  </video>

                  <div className='firsthalfsection__box-overlay' onMouseOver={this.playRealVideo} onMouseLeave={this.pauseRealVideo}>
                    <div className='firsthalfsection__box-overlay-header'>
                      <h1>BUILD</h1>
                      <h5>Brick by brick, stone by stone, we bring your plan to life. </h5>
                      <a href='https://www.youtube.com/watch?v=RkmpKMUN1GU' target="_blank" >See whole video.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-sm-4 col-xs-12 firsthalfsection-col-3'>
              <div className='firsthalfsection__box-outer firsthalfsection__box-outer-3'>
                <div className='firsthalfsection__box-circle'>
                  <h1>3</h1>
                </div>

                <div className='firsthalfsection__box-inner firsthalfsection__box-inner-3'>
                  <div className='firsthalfsection__box-overlay'>
                    <div className='firsthalfsection__box-overlay-header'>
                      <h1>MAINTAIN</h1>
                      <h5>We upkeep the final product to keep your landscape looking brand new.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default FirstHalfSection;