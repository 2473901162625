import React from 'react';
import { Link } from 'react-router-dom';

import { PHONE_NUMBER_STR, PHONE_NUMBER_TEL } from 'global_variables';

import GreenLinkButton from 'components/Buttons/GreenLinkButton';
import YellowLinkButton from 'components/Buttons/YellowLinkButton';

import Cash from './images/notes.png';
import Check from './images/check.png';
import Venmo from './images/venmo.png';
import Visa from './images/visa.png';
import Amex from './images/amex.png';
import Master from './images/mastercard.png';
import Discover from './images/discover.png';
import Paypal from './images/paypal.png';
import Instagram from './images/instagram.png';

import './Footer.scss';

class Footer extends React.Component {

  render() {
    return (
      <div className="footer-container">
        <div className='row footer-container__creditcard footer-container__creditcard-1'>
          <div className='footer-container__creditcard-icon footer-container__creditcard-visa'>
            <img src={Visa} alt='visaicon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-amex'>
            <img src={Amex} alt='amexicon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-master'>
            <img src={Master} alt='mastericon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-discover'>
            <img src={Discover} alt='discovericon' />
          </div>
        </div>

        <div className='row footer-container__creditcard footer-container__creditcard-2'>
          <div className='footer-container__creditcard-icon footer-container__creditcard-cash'>
            <img src={Cash} alt='cashicon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-check'>
            <img src={Check} alt='checkicon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-venmo'>
            <img src={Venmo} alt='venmoicon' />
          </div>
          <div className='footer-container__creditcard-icon footer-container__creditcard-paypal'>
            <img src={Paypal} alt='paypalicon' />
          </div>
        </div>

        <div className="row footer-container__row">
          <div className="col-xs-12 col-sm-4  col-md-4 col-lg-4 footer-container__col-right">
            
            <div className="footer-container__free-inspection-btn">
                <GreenLinkButton url='/contact#freequote'>Get Your Free Quote</GreenLinkButton>
            </div>

            <div className="footer-container__offers-btn ">
                <YellowLinkButton url='/contact#specialoffers'>Special Offers</YellowLinkButton>
            </div>

          </div>

          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 footer-container__col-mid">
            <div className="footer-container__links">
              <div className="row">
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/about#ourteam">TEAM</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/about#reviews">REVIEWS</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/services">SERVICES</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/gallery">GALLERY</Link>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/contact#map">LOCATIONS</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/about#faq">FAQ</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/blog">BLOG</Link>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 footer-container__links-col">
                  <Link to="/contact#freequote">CONTACT</Link>
                </div>
              </div>
            </div>

            <div className="footer-container__border-2" />

            <div className="footer-container__phone">
              <a href={PHONE_NUMBER_TEL}>
                <span className="glyphicon glyphicon-earphone" />
                <span>{PHONE_NUMBER_STR}</span>
              </a>
            </div>
          </div>

          <div className="col-xs-12 col-sm-4  col-md-4 col-lg-4 footer-container__col-left">
            <div className="footer-container__connect">CONNECT WITH US!</div>
            <div className="footer-container__social-media">
              <a
                href="https://www.facebook.com/Neumannbrotherslandscape/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer-container__social-media-btn"
                  src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDExMi4xOTYgMTEyLjE5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTEyLjE5NiAxMTIuMTk2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8Y2lyY2xlIHN0eWxlPSJmaWxsOiMzQjU5OTg7IiBjeD0iNTYuMDk4IiBjeT0iNTYuMDk4IiByPSI1Ni4wOTgiLz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNNzAuMjAxLDU4LjI5NGgtMTAuMDF2MzYuNjcySDQ1LjAyNVY1OC4yOTRoLTcuMjEzVjQ1LjQwNmg3LjIxM3YtOC4zNCAgIGMwLTUuOTY0LDIuODMzLTE1LjMwMywxNS4zMDEtMTUuMzAzTDcxLjU2LDIxLjgxdjEyLjUxaC04LjE1MWMtMS4zMzcsMC0zLjIxNywwLjY2OC0zLjIxNywzLjUxM3Y3LjU4NWgxMS4zMzRMNzAuMjAxLDU4LjI5NHoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                  alt='social-media-fb'
                />
              </a>
              <a
                href="mailto:craig@neumannbrotherslandscape.com?Subject=Requesting%20Information"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer-container__social-media-btn"
                  src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ4NS40MTEgNDg1LjQxMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDg1LjQxMSA0ODUuNDExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnPgoJPHBhdGggZD0iTTAsODEuODI0djMyMS43NjNoNDg1LjQxMVY4MS44MjRIMHogTTI0Mi43MDgsMjgwLjUyNkw0My42MTIsMTA1LjY5MWgzOTguMTg3TDI0Mi43MDgsMjgwLjUyNnogICAgTTE2My4zOTcsMjQyLjY0OUwyMy44NjcsMzY1LjE3OFYxMjAuMTE5TDE2My4zOTcsMjQyLjY0OXogTTE4MS40ODIsMjU4LjUzM2w2MS4yMiw1My43NjJsNjEuMjItNTMuNzYyTDQ0MS45MjQsMzc5LjcySDQzLjQ4NyAgIEwxODEuNDgyLDI1OC41MzN6IE0zMjIuMDA4LDI0Mi42NTVsMTM5LjUzNS0xMjIuNTM2djI0NS4wNTlMMzIyLjAwOCwyNDIuNjU1eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="
                  alt='mail'
                />
              </a>
            </div>

            
            <div className="footer-container__review-btn">
              <GreenLinkButton url='/contact#freequote'>Contact Us</GreenLinkButton>
            </div>
          </div>
        </div>

        <div className="footer-container__copyright">
          &copy; {new Date().getFullYear()} Neumann Brothers Landcape LLC. All rights reserved.
          <Link to='/privacypolicy'><div className="footer-container__privacy-policy">Privacy Policy</div></Link>
          <div className='footer-container__createdby'>created by <a href="mailto:hello@pasindu.io" target='_blank' rel="noopener noreferrer">hello@pasindu.io</a></div>
        </div>
      </div>
    );
  }
}

export default Footer;
