import React, { Component } from 'react'
import validator from 'validator';
import axios from 'axios';

import './SpecialOffer.scss';

import { PHONE_NUMBER_STR, PHONE_NUMBER_TEL } from 'global_variables';

class SpecialOffer extends Component {

  constructor(props) {
    super(props);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.sendOffer = this.sendOffer.bind(this);

    this.state = {
      offer1: 1,
      offer1email: '',
      offer1error: null,
    }
  }

  handleKeyPress(event, number) {
    if(event.key === 'Enter') {
        this.sendOffer(number);
    }
  }

  sendOffer(number) {
    let email = this.state.offer1email;
    console.log(number);
    console.log(email)
   
    if (!validator.isEmail(email)) {
        this.setState({
          offer1error: true
        });
    } else {
        axios.post('/api/offers', {
          offer: String(number), 
          email: email,
        })
        .then((response) => {
            this.setState({
              offer1: 3,
              offer1email: '',
              offer1error: null,
            });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
              offer1: 4,
              offer1email: '',
              offer1error: null,
          });
        });
      }
  }

  render() {
    return (
      
      <div className="specialoffer__body-offers-discount">
        <div className="specialoffer__body-offers-discount-header">
          {this.props.title}
        </div>
        <div className="specialoffer__body-offers-discount-content">
          {this.props.description}
        </div>
        
        {this.state.offer1 === 1 && <div className="specialoffer__body-offers-discount-redeem">
          <div className="specialoffer__body-offers-discount-redeem-btn" onClick={ () => this.setState({ offer1: 2}) }>
            <div className="">
              <span>Redeem</span>
            </div>
          </div>
        </div>}

        {this.state.offer1 === 2 && <div className="specialoffer__body-offers-discount-redeem">
          <input
              value={this.state.offer1email}
              onChange={(evt) => { this.setState({ offer1email: evt.target.value}) }} 
              type="text"
              className="specialoffer__body-offers-discount-redeem-input"
              placeholder="Email"
              onKeyPress={ (evt) => this.handleKeyPress(evt, this.props.offerNumber) }
            />
            <div className="specialoffer__body-offers-discount-redeem-arrow-btn" onClick={ () => this.sendOffer(this.props.offerNumber) }>
              <div className="">
                <span className="glyphicon glyphicon-arrow-right" />
              </div>
            </div>
        </div>}

        {this.state.offer1 === 3 && <div className="specialoffer__body-offers-discount-redeem">
          <div className="specialoffer__body-offers-discount-redeem-sent"> 
            Offer sent to email!
          </div>
        </div>}


        {this.state.offer1error && <div className="specialoffer__body-offers-discount-redeem">
          <div className="specialoffer__body-offers-discount-redeem-error"> 
            Please enter a valid email.
          </div>
        </div>}

        {this.state.offer1 === 4 && <div className="specialoffer__body-offers-discount-redeem">
          <div className="specialoffer__body-offers-discount-redeem-error"> 
           Something went wrong. Please contact us at <a href={PHONE_NUMBER_TEL}>{PHONE_NUMBER_STR}</a>.
          </div>
        </div>}

      </div>

    )
  }

}

export default SpecialOffer;
