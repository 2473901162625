import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import { PHONE_NUMBER_STR, PHONE_NUMBER_TEL } from 'global_variables';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';
import Review from 'components/Review';
import Rating from 'components/Rating';

import Logo from 'global_images/menu-logo.png';
import ProfilePic1 from './images/profilepic1.jpg';
import ProfilePic2 from './images/profilepic2.jpg';
import ProfilePic3 from './images/profilepic3.jpg';
import WhyUs3 from './images/whyus-3.jpg';

import TeamIcon from './images/teamicon.png';
import WhyUsIcon from './images/whyusicon.png';
import StoryIcon from './images/storyicon.png';
import ReviewsIcon from './images/reviewsicon.png';
import FAQIcon from './images/faqicon.png';
import CalculatorIcon from './images/calculatoricon1.png';


import Profile1 from 'components/HomePage/ThirdSection/images/profile1.jpg';
import Profile2 from 'components/HomePage/ThirdSection/images/profile2.jpg';
import Profile3 from 'components/HomePage/ThirdSection/images/profile3.png';

import './AboutPage.scss';



class AboutPage extends Component {

  constructor(props){
    super(props);

    this.goToSection = this.goToSection.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (_.has(this.props, ['location', 'hash']) && _.has(prevProps, ['location', 'hash'])) {
      if( this.props.location !== prevProps.location ) {
        this.goToSection(this.props.location.hash.substring(1));
      }
    }
  }

  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="aboutpage">

        <div className='aboutpage__banner'>
          <div className='aboutpage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='aboutpage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='aboutpage__body'>
          
          <Element name="ourteam">
            <div className='aboutpage__ourteam'>

              <div className='aboutpage__ourteam-icon'>
                <img src={TeamIcon} alt='teamicon' />
                <h1>Our Team</h1>
              </div>

              <div className='aboutpage__ourteam-subheader'>
                With decades of landscaping experience and design skills under our belt, our team at Neumann Brothers is here to serve.
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-4 col-md-4'>

                  <div className='aboutpage__ourteam-profile-div'>
                    <div className='aboutpage__ourteam-profile'>
                      <div className='aboutpage__ourteam-profile-pic'>
                        <img src={ProfilePic3} alt='profilepic' />
                      </div>

                      <div className='aboutpage__ourteam-profile-text'>

                        <div className='aboutpage__ourteam-profile-name'>
                          <h3>Ty Neumann</h3>
                        </div>

                        <div className='aboutpage__ourteam-profile-title'>
                          Founder and Designer
                        </div>

                        <div className='aboutpage__ourteam-profile-bio'>
                          Ty was born and raised in St George. Married to his beautiful wife for 8 years and father to 3 boys, 
                          he enjoys exercise and spending time with his family and friends. Ty enjoys landscaping as
                          everyday brings a new opportunity to beautify and transform the community one home at a time.
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className='col-xs-12 col-sm-4 col-md-4'>

                  <div className='aboutpage__ourteam-profile-div'>
                    
                    <div className='aboutpage__ourteam-profile'>
                      <div className='aboutpage__ourteam-profile-pic'>
                        <img src={ProfilePic2} alt='profilepic' />
                      </div>

                      <div className='aboutpage__ourteam-profile-text'>

                        <div className='aboutpage__ourteam-profile-name'>
                          <h3>Spencer Harmon</h3>
                        </div>

                        <div className='aboutpage__ourteam-profile-title'>
                          Maintenance Manager
                        </div>

                        <div className='aboutpage__ourteam-profile-bio'>
                          Spencer handles the vital part of the operations of making sure your property is 
                          looking its best. He focuses on everything from mowing to trimming plants 
                          and trees and so much more. You can catch Spencer in the dirt, 
                          helping with upgrades or installations in your new yard. 
                        </div>

                      </div>

                    </div>
                  </div>

                </div>

                <div className='col-xs-12 col-sm-4 col-md-4'>
                  <div className='aboutpage__ourteam-profile-div'>
                    <div className='aboutpage__ourteam-profile'>
                    <div className='aboutpage__ourteam-profile-pic'>
                      <img src={ProfilePic1} alt='profilepic' />
                    </div>

                    <div className='aboutpage__ourteam-profile-text'>

                      <div className='aboutpage__ourteam-profile-name'>
                        <h3>Nick Neumann</h3>
                      </div>

                      <div className='aboutpage__ourteam-profile-title'>
                        Founder and Designer
                      </div>

                      <div className='aboutpage__ourteam-profile-bio'>
                        Nick was born and raised in St. George, UT and is the youngest of nine children.
                           He gained a desire to own a landscaping business when he was younger. 
                           He loves to work outside and finds pride in working hard to ensure you're satisfied with a beautiful yard.
                      </div>

                    </div>
                  </div>

                  </div>
                </div>

              </div>
            </div>
          </Element>


          <Element name="whyus">  
            <div className='aboutpage__whyus'>

              <div className='aboutpage__whyus-icon'>
                <img src={WhyUsIcon} alt='whyusicon' />
                <h1>Why Us?</h1>
              </div>

              <div className='aboutpage__whyus-subheader'>


                 <div className='aboutpage__whyus-subheader-points'>
                   <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <li><strong>Easy</strong> to work with</li>
                      <li><strong>Reliable</strong> and on time</li>
                      <li><strong>Experienced</strong> workers</li>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <li><strong>Detail</strong> oriented</li>
                      <li><strong>Meticulous</strong> planners</li>
                      <li><strong>Licensed & insured</strong></li>
                    </div>
                   </div>
                 </div>

                 <WhiteLinkButton url='/gallery'>CHECK OUT OUR WORK</WhiteLinkButton>

                 <h3>...and we are always just a <strong><a href={PHONE_NUMBER_TEL}>call</a></strong> away!</h3>

              </div>
            </div>
          </Element>



          <Element name="ourstory">  
            <div className='aboutpage__story'>

              <div className='aboutpage__story-icon'>
                <img src={StoryIcon} alt='storyicon' />
                <h1>Story</h1>
              </div>

              <div className='aboutpage__story-pic'>
                <img src={WhyUs3} alt='story'/>
              </div>

              <div className='aboutpage__story-text'>
                <strong>Neumann Brothers Landscape</strong> officially began in 2018. Thanks to a father who taught us the value of hard work, 
                the foundation for our business was decades in the making. We are products of Southern Utah, Born and raised. 
                Our father and oldest brother have been operating Neumann Construction since the mid 1990’s. 
                We (as the youngest brothers in our family) have had the great privilege of working alongside 
                our older brothers and father for much of our lives. Many of our greatest memories growing up 
                are from working at one of the many “Sweat Pits”, or houses being built for either a family 
                member or mom and dad. 
                <br />
                <br />

                The business caries our name to ensure we honor our parents and family 
                as we create a product and service, we can be proud of. Afterall, we will liver here and we 
                love it here! We look forward to serving our great and growing community and teaching work 
                ethic to our own children. Don’t be surprised if you see our kid working alongside us from 
                time to time! Let us prove to you “We do the Work”!
               </div>
            </div>
          </Element>


          <Element name="reviews">  
            <div className='aboutpage__reviews'>
              
              <div className='aboutpage__reviews-icon'>
                <img src={ReviewsIcon} alt='reviewsicon' />
                <h1>Reviews</h1>
              </div>
      

              <div className='aboutpage__reviews-bubbles'>
                <Review text='"They did a great job on our underground trampoline, and yard!"– Kali B.' img={Profile1} href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/'/>
                <Review text='"This was the first landscaping company we have come across in St. George..." – Jennifer T.' img={Profile2} href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/' />
                <Review text='"They explained exactly what the issues were and gave me several options..." - Pasindu W.' img={Profile3} href='https://www.google.com/search?_ga=2.198544303.168465222.1565540862-329343984.1565223268&q=Neumann+Brothers+Landscape&ludocid=10013095038587562179&lsig=AB86z5WH23pBFS22EqYiWVqHP25a#lrd=0x80ca51b8bf2bb0cf:0x8af5a8e2cd3ce8c3,1,,,'/>
              </div>

              <div className='aboutpage__reviews-bubbles'>
                <Rating rating='yelp'/>
                <Rating rating='facebook'/>
                <Rating rating='google'/>
              </div>
            </div>
          </Element>


          <Element name="faq">
            <div className='aboutpage__faq'>
              
              <div className='aboutpage__faq-icon'>
                <img src={FAQIcon} alt='faqicon' />
                <h1>FAQ</h1>
              </div>

              <div className='aboutpage__faq-body'>
                <div className='row'>
                  
                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q: Who will work on my property? 
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A: One of our lead team members will always be there for the job. You can check out our team
                        <Link to='/about#ourteam'> above.</Link> 
                      </div>
                    </div>
                  </div>

                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q: Are you a licensed Landscaper?
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A: Yes, I hold all the necessary licenses and to operate a business and work on all your landscaping needs.
                      </div>
                    </div>
                  </div>

                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q:  Do you fix leaks in sprinkler systems?
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A:  Yes, we will find the leaks and repair the sprinklers or valves that are leaking - all while doing a very neat job!
                      </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>

                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q: Do you trim and remove trees? 
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A: We will prune trees and canopy them. We can remove regular trees up to 10ft tall. We are not arborists so we do not climb trees or cut them down. However, we will trim palm trees taller than 10ft.
                      </div>
                    </div>
                  </div>

                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q: Do you maintain my sprinklers with the monthly yard maintenance?
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A:  Yes, we will make sure your lawn and plants are getting watered correctly and let you know if there any issues you need to be aware of.
                      </div>
                    </div>
                  </div>

                  <div className='col-xs-12 col-sm-4'>
                    <div className='aboutpage__faq-bubble'>
                      <div className='aboutpage__faq-bubble-question'>
                        Q: Will you pull weeds?
                      </div>
                      <div className='aboutpage__faq-bubble-answer'>
                        A: If it is in an existing yard, we will do some weed pulling but if it's very bad, we will trim them and use weed killer to control them.  
                      </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>

                </div>


              </div>
            </div>
          </Element>


          <Element name="calculator"> 
            <div className='aboutpage__calculator'>
              
              <div className='aboutpage__calculator-icon'>
                <img src={CalculatorIcon} alt='calculatoricon' />
                <h1>Landscape Calculator</h1>
              </div> 

              <h3>Coming soon!</h3>
            </div>
          </Element>

        </div>

      </div>
    )
  }

}

export default withRouter(AboutPage);