import React, { Component } from 'react'
import * as Scroll from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
 
import './HomePage.scss';

import FirstSection from './FirstSection/';
import FirstHalfSection from './FirstHalfSection/';
import SecondSection from './SecondSection/';
import ThirdSection from './ThirdSection/';
import FourthSection from './FourthSection/';
import FifthSection from './FifthSection/';
import SixthSection from './SixthSection/';
import SeventhSection from './SeventhSection/';

class HomePage extends Component {

  componentDidMount() {
    this.scrollToTheTop(false, 0);
  }

  scrollToTheTop(smooth, duration) {
    scroll.scrollToTop({
      smooth: smooth,
      duration: duration
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.scrollToTheTop(true, 1000);
    }
  }

  render() {
    return (
      <div className="homepage">
        <FirstSection />
        <FirstHalfSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <SeventhSection />
      </div>
    )
  }

}

export default withRouter(HomePage);