import React from 'react';
import {GoogleApiWrapper, Map, Polygon, Marker} from 'google-maps-react';
import Geocode from "react-geocode";
import geolib from 'geolib';
import './GoogleMap.scss';
import GreenButton from 'components/Buttons/GreenButton';
import GreenButtonLoading from 'components/Buttons/GreenButtonLoading';

Geocode.setApiKey("AIzaSyB26SC0HTJv12QYjD4cEotO1dy7hQ3YGnY");

const style = {
  width: '98%',
  height: '360px',
  position: 'relative',
  margin: '0 auto',
  textAlign: 'center',
  paddingTop: '15px'
}

export class GoogleMaps extends React.Component {
  constructor() {
    super();

    this.state = {
      address: '',
      loading: null,
      error: null,
      valid: null,
      invalid: null,
      marker: null,
    };

    this.checkAddress = this.checkAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  renderMarkers() {
    if (this.state.marker) {
        return (
          <Marker
            title={'this is your home'}
            name={'home'}
            position={this.state.marker} 
          />
        );
    } else {
        return null;
    }
  }

  checkAddress() {
    if(!this.state.address) {
      return false;
    }

    this.setState({
      loading: true,
      error: null,
      valid: null,
      invalid: null,
    }, () => {
        Geocode.fromAddress(this.state.address).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            let valid = this.checkIfAddressIsInBounds(lat, lng);

            if (valid) {
                this.setState({
                  marker: { lat: lat, lng: lng },
                  loading: null, 
                  valid: true,
                  invalid: null,
                });
            } else {
                this.setState({
                  marker: { lat: lat, lng: lng },
                  loading: null, 
                  valid: null,
                  invalid: true
                });
            }
          },
          (error) => {
             this.setState({
                loading: false,
                error: true,
                valid: null,
                invalid: null,
                marker: null,
              });
          });
    });
  }

  checkIfAddressIsInBounds(lat, lng) {
    return geolib.isPointInside(
      {latitude: lat, longitude: lng},
      [
        {latitude: 37.24837, longitude: -113.17094},
        {latitude: 37.43071, longitude: -113.50516},
        {latitude: 37.36337,longitude: -113.69749},
        {latitude: 37.33842,longitude: -113.7346},
        {latitude: 37.33959,longitude: -113.76758}, 
        {latitude: 37.3211,longitude: -113.77858}, 
        {latitude: 37.28785,longitude: -113.80205}, 
        {latitude: 37.06039,longitude: -113.69245},
        {latitude: 37.0421,longitude: -113.59057},
        {latitude: 37.03843,longitude: -113.47508},
        {latitude:37.2034,longitude: -113.21952},
        {latitude: 37.21113,longitude: -113.20141},
        {latitude: 37.22514,longitude: -113.17987}
      ]
    );
  }

  handleChange(event) {
    this.setState({
      address: event.target.value
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter'){
      this.checkAddress();
    }
  }

  render() {
    const serviceArea = [
      {lat: 37.24837, lng: -113.17094},
      {lat: 37.43071, lng: -113.50516},
      {lat: 37.36337,lng: -113.69749},
      {lat: 37.33842,lng: -113.7346},
      {lat: 37.33959,lng: -113.76758}, 
      {lat: 37.3211,lng: -113.77858}, 
      {lat: 37.28785,lng: -113.80205}, 
      {lat: 37.06039,lng: -113.69245},
      {lat: 37.0421,lng: -113.59057},
      {lat: 37.03843,lng: -113.47508},
      {lat:37.2034,lng: -113.21952},
      {lat: 37.21113,lng: -113.20141},
      {lat: 37.22514,lng: -113.17987}
    ];

    return (
      <div className='google-maps-component'>
        <div className="google-maps-component__check-location">
          <input
            value={this.state.address}
            onChange={this.handleChange} 
            type="text"
            className="google-maps-component__check-location-input"
            placeholder="123 Main Street, St. George UT, 84765"
            name="location-finder"
            onKeyPress={this.handleKeyPress}
          />
          {!this.state.loading && <GreenButton cb={this.checkAddress}>GO</GreenButton>}

          {this.state.loading && <GreenButtonLoading></GreenButtonLoading>}
        </div>
        
        {this.state.valid && <div className='google-maps-component__valid'>
          <span className='glyphicon glyphicon-ok-sign'></span>Good news, we service your area! 
          <br />
          <div className='google-maps-component__valid-link'>
            <div className='google-maps-contact-now' onClick={ () => this.props.cb(this.state.address, true) }>Contact us now</div> for your free estimate!
          </div>
        </div>}

        {this.state.invalid && <div className='google-maps-component__invalid'>
          We apologize but we do not service your area yet. &#9785; <br />
          <div className='google-maps-contact-now' onClick={ () => this.props.cb(this.state.address, false) }>Contact us now</div> and we will try our best to assist you.
        </div>}

        {this.state.error && <div className='google-maps-component__error'>
          Please enter a valid address.
        </div>}

        <Map 
          google={this.props.google} 
          style={style} 
          containerStyle={style}
          initialCenter={{lat: 37.237740, lng: -113.530710}}
          zoom={9.9}>

          <Polygon
            paths={serviceArea}
            strokeColor="green"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="green"
            fillOpacity={0.35} 
          />

          {this.renderMarkers()}

          
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB26SC0HTJv12QYjD4cEotO1dy7hQ3YGnY'
})(GoogleMaps)
