import React, { Component } from 'react'

import './Button.scss';

class GreenButtonLoading extends Component {

  render() {
    return (
      <div className="commonbutton greenbuttonloading">
        <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
        {this.props.children}
      </div>
    )
  }

}

export default GreenButtonLoading;