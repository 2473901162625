import React, { Component } from 'react'

import './SixthSection.scss';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';

class SixthSection extends Component {

  render() {
    return (
      <div className="sixthsection">

        <div className='sixthsection__subheader'>
          SO WHY US?
        </div>

        <div className='sixthsection__header'>
          <h1>We take the time to understand your needs.</h1>
        </div>

        <div className='sixthsection__facts'>
          <div className='row'>

            <div className='col-xs-12 col-sm-6'>
              <div className='sixthsection__facts-single'>
                IN PERSON CONSULTATIONS
              </div>
            </div>

            <div className='col-xs-12 col-sm-6'>
              <div className='sixthsection__facts-single'>
                TRUSTWORTHY
              </div>
            </div>

            <div className='col-xs-12 col-sm-6'>
              <div className='sixthsection__facts-single'>
                TIMELY
              </div>
            </div>

            <div className='col-xs-12 col-sm-6'>
              <div className='sixthsection__facts-single'>
                OUR WORD IS OUR BOND
              </div>
            </div>

          </div>

        </div>

        <WhiteLinkButton url='/about#ourstory'>MORE ABOUT US</WhiteLinkButton>

      </div>
    )
  }

}

export default SixthSection;