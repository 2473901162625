import React, { Component } from 'react'

import './ThirdSection.scss';

import Rating from 'components/Rating';
import Review from 'components/Review';

import Profile1 from './images/profile1.jpg';
import Profile2 from './images/profile2.jpg';
import Profile3 from './images/profile3.png';

class ThirdSection extends Component {

  render() {

    return (
      <div className='thirdsection'>
        
        <div className='thirdsection__stars'>
          <span className='glyphicon glyphicon-star' />
          <span className='glyphicon glyphicon-star' />
          <span className='glyphicon glyphicon-star' />
          <span className='glyphicon glyphicon-star' />
          <span className='glyphicon glyphicon-star' />
        </div>

        <div className='thirdsection__header'>
          <h1>Customer satisfaction is our #1 priority.</h1>
        </div>

        <div className='thirdsection__ratings'>

          <div className='row'>
            <div className='col-xs-6 col-sm-4 '>
               <Rating rating='yelp'/>
            </div>
             <div className='col-xs-6 col-sm-4 '>
               <Rating rating='facebook' />
            </div>
             <div className='col-xs-12 col-sm-4'>
               <Rating rating='google' />
            </div>

          </div>

        </div>

        <div className='thirdsection__reviews'>
          <div className='row'> 
            <div className='col-xs-12 col-sm-4 thirdsection__reviews-col'>
              <Review text='"They did a great job on our underground trampoline, and yard!"– Kali B.' img={Profile1} href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/'/>
            </div>
            <div className='col-xs-12 col-sm-4 thirdsection__reviews-col'>
              <Review text='"This was the first landscaping company we have come across in St. George..." – Jennifer T.' img={Profile2} href='https://www.facebook.com/pg/Neumannbrotherslandscape/reviews/' />
            </div>
            <div className='col-xs-12 col-sm-4 thirdsection__reviews-col'>
              <Review text='"They explained exactly what the issues were and gave me several options ... - Pasindu W' img={Profile3} href='https://www.google.com/search?_ga=2.198544303.168465222.1565540862-329343984.1565223268&q=Neumann+Brothers+Landscape&ludocid=10013095038587562179&lsig=AB86z5WH23pBFS22EqYiWVqHP25a#lrd=0x80ca51b8bf2bb0cf:0x8af5a8e2cd3ce8c3,1,,,'/>
            </div>
          </div>
        </div>
      

      </div>
    )
  }

}

export default ThirdSection;