import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Button.scss';

class WhiteLinkButton extends Component {

  render() {
    return (
      <Link to={this.props.url} className='commonbutton-phone'> 
        <div className="commonbutton whitebutton">
          {this.props.children}
        </div>
      </Link>
    )
  }

}

export default WhiteLinkButton;