import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

import { PHONE_NUMBER_STR } from 'global_variables';

import WhiteLinkButton from 'components/Buttons/WhiteLinkButton';
import GreenPhoneButton from 'components/Buttons/GreenPhoneButton';
import ToggleSwitch from 'components/ThreeWayToggle';
import SeventhSection from 'components/HomePage/SeventhSection';

import Logo from 'global_images/menu-logo.png';
import GalleryIcon from './images/gallery.png';

import BlytheBefore1 from './images/BlytheJob/Before/blythe-before-1.jpg';
import BlytheBefore2 from './images/BlytheJob/Before/blythe-before-2.jpg';
import BlytheBefore3 from './images/BlytheJob/Before/blythe-before-3.jpg';
import BlytheBefore4 from './images/BlytheJob/Before/blythe-before-4.jpg';

import BlytheDuring1 from './images/BlytheJob/During/blythe-during-1.jpg';
import BlytheDuring2 from './images/BlytheJob/During/blythe-during-2.jpg';
import BlytheDuring3 from './images/BlytheJob/During/blythe-during-3.jpg';
import BlytheDuring4 from './images/BlytheJob/During/blythe-during-4.jpg';

import BlytheAfter1 from './images/BlytheJob/After/blythe-after-1.jpg';
import BlytheAfter2 from './images/BlytheJob/After/blythe-after-2.jpg';
import BlytheAfter3 from './images/BlytheJob/After/blythe-after-3.jpg';
import BlytheAfter4 from './images/BlytheJob/After/blythe-after-4.jpg';

import ChristiansenBefore1 from './images/Christiansen/Before/christiansen-before-1.jpg';
import ChristiansenBefore2 from './images/Christiansen/Before/christiansen-before-2.jpg';
import ChristiansenBefore3 from './images/Christiansen/Before/christiansen-before-3.jpg';
import ChristiansenBefore4 from './images/Christiansen/Before/christiansen-before-4.jpg';

import ChristiansenDuring1 from './images/Christiansen/During/christiansen-during-1.jpg';
import ChristiansenDuring2 from './images/Christiansen/During/christiansen-during-2.jpg';
import ChristiansenDuring3 from './images/Christiansen/During/christiansen-during-3.jpg';
import ChristiansenDuring4 from './images/Christiansen/During/christiansen-during-4.jpg';

import ChristiansenAfter1 from './images/Christiansen/After/christiansen-after-1.jpg';
import ChristiansenAfter2 from './images/Christiansen/After/christiansen-after-2.jpg';
import ChristiansenAfter3 from './images/Christiansen/After/christiansen-after-3.jpg';
import ChristiansenAfter4 from './images/Christiansen/After/christiansen-after-4.jpg';

import CleanupsBefore1 from './images/Cleanups/Before/cleanups-before-1.jpg';
import CleanupsBefore2 from './images/Cleanups/Before/cleanups-before-2.jpg';

import CleanupsAfter1 from './images/Cleanups/After/cleanups-after-1.jpg';
import CleanupsAfter2 from './images/Cleanups/After/cleanups-after-2.jpg';
import CleanupsAfter3 from './images/Cleanups/After/cleanups-after-3.jpg';
import CleanupsAfter4 from './images/Cleanups/After/cleanups-after-4.jpg';

import FrontYardDuring1 from './images/FrontYardRedesign/During/frontyardredesign-during-1.jpeg';
import FrontYardDuring2 from './images/FrontYardRedesign/During/frontyardredesign-during-2.jpg';
import FrontYardDuring3 from './images/FrontYardRedesign/During/frontyardredesign-during-3.jpg';
import FrontYardDuring4 from './images/FrontYardRedesign/During/frontyardredesign-during-4.jpg';

import FrontYardAfter1 from './images/FrontYardRedesign/After/frontyardredesign-after-1.jpeg';
import FrontYardAfter2 from './images/FrontYardRedesign/After/frontyardredesign-after-2.jpg';
import FrontYardAfter3 from './images/FrontYardRedesign/After/frontyardredesign-after-3.jpg';
import FrontYardAfter4 from './images/FrontYardRedesign/After/frontyardredesign-after-4.jpg';

import DrainRockDuring1 from './images/DrainRockInstallation/During/drainrockinstallation-during-1.jpeg';
import DrainRockDuring2 from './images/DrainRockInstallation/During/drainrockinstallation-during-2.jpeg';
import DrainRockDuring3 from './images/DrainRockInstallation/During/drainrockinstallation-during-3.jpg';
import DrainRockDuring4 from './images/DrainRockInstallation/During/drainrockinstallation-during-4.jpg';

import DrainRockAfter1 from './images/DrainRockInstallation/After/drainrockinstallation-after-1.jpg';
import DrainRockAfter2 from './images/DrainRockInstallation/After/drainrockinstallation-after-2.jpg';

import MassiveLeakDuring1 from './images/MassiveLeakFix/During/massiveleakfix-during-1.jpg';
import MassiveLeakDuring2 from './images/MassiveLeakFix/During/massiveleakfix-during-2.jpg';
import MassiveLeakDuring3 from './images/MassiveLeakFix/During/massiveleakfix-during-3.jpg';
import MassiveLeakDuring4 from './images/MassiveLeakFix/During/massiveleakfix-during-4.jpg';

import MassiveLeakAfter1 from './images/MassiveLeakFix/After/massiveleakfix-after-1.jpeg';

import GrassRemovalBefore1 from './images/GrassRemovalRock/Before/grassremovalrock-before-1.jpeg';

import GrassRemovalDuring1 from './images/GrassRemovalRock/During/grassremovalrock-during-1.jpeg';
import GrassRemovalDuring2 from './images/GrassRemovalRock/During/grassremovalrock-during-2.jpeg';
import GrassRemovalDuring3 from './images/GrassRemovalRock/During/grassremovalrock-during-3.jpeg';
import GrassRemovalDuring4 from './images/GrassRemovalRock/During/grassremovalrock-during-4.jpeg';

import GrassRemovalAfter1 from './images/GrassRemovalRock/After/grassremovalrock-after-1.jpeg';
import GrassRemovalAfter2 from './images/GrassRemovalRock/After/grassremovalrock-after-2.jpeg';

import Concrete1 from './images/ConcreteWork/concrete-work-1.jpg';
import Concrete2 from './images/ConcreteWork/concrete-work-2.jpg';

import './Gallery.scss';

class GalleryPage extends Component {

  constructor(props){
    super(props);

    this.goHome = this.goHome.bind(this);
    this.changeBlytheState = this.changeBlytheState.bind(this);
    this.changeChristiansenState = this.changeChristiansenState.bind(this);
    this.changeCleanupsState = this.changeCleanupsState.bind(this);
    this.changeFrontyardState = this.changeFrontyardState.bind(this);
    this.changeDrainRockState = this.changeDrainRockState.bind(this);
    this.changeMassiveLeakState = this.changeMassiveLeakState.bind(this);
    this.changeGrassRemovalState = this.changeGrassRemovalState.bind(this);

    this.goToSection = this.goToSection.bind(this);


    this.openViewer = this.openViewer.bind(this)

     this.state = {
      visible: false,
      images: [],
      blythe: 'after',
      christiansen: 'after',
      cleanups: 'after',
      frontyard: 'after',
      drainrock: 'during',
      leak: 'during',
      grass: 'during',
      activeIndex: 0,
    };
  }

  componentDidMount() {
    if (_.has(this.props, ['location', 'hash'])) {
      this.goToSection(this.props.location.hash.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (_.has(this.props, ['location', 'hash']) && _.has(prevProps, ['location', 'hash'])) {
      if( this.props.location !== prevProps.location ) {
        this.goToSection(this.props.location.hash.substring(1));
      }
    }
  }

  goToSection(hash) {
    if (hash !== '') {
        scroller.scrollTo(hash, {
          offset: -120
        });
    } else {
        scroll.scrollToTop({
          smooth: false,
          duration: 0
        });
    }
  } 

  goHome() {
    this.props.history.push('/');
  }

  openViewer(project, state, index) {
    let imgArr = [];

    switch (project) {
      case 'blythe': 
        switch (state){
          case 'before':
            imgArr = this.blytheBefore();
            break;
          case 'during':
            imgArr = this.blytheDuring();
            break;
          case 'after':
            imgArr = this.blytheAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'christiansen': 
        switch (state){
          case 'before':
            imgArr = this.christiansenBefore();
            break;
          case 'during':
            imgArr = this.christiansenDuring();
            break;
          case 'after':
            imgArr = this.christiansenAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'cleanups': 
        switch (state){
          case 'before':
            imgArr = this.cleanupsBefore();
            break;
          case 'after':
            imgArr = this.cleanupsAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'frontyard': 
        switch (state){
          case 'during':
            imgArr = this.frontyardredesignDuring();
            break;
          case 'after':
            imgArr = this.frontyardredesignAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'drainrock': 
        switch (state){
          case 'during':
            imgArr = this.drainrockDuring();
            break;
          case 'after':
            imgArr = this.drainrockAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'leak': 
        switch (state){
          case 'during':
            imgArr = this.leakDuring();
            break;
          case 'after':
            imgArr = this.leakAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'grass': 
        switch (state){
          case 'before':
            imgArr = this.grassBefore();
            break;
          case 'during':
            imgArr = this.grassDuring();
            break;
          case 'after':
            imgArr = this.grassAfter();
            break;
          default:
            imgArr = [];
            break;
        }
        break;
      case 'concrete': 
        imgArr = this.concrete();
        break;
      default:
        imgArr = [];
        break;
    }

    this.setState({
      visible: true, 
      images: imgArr,
      activeIndex: index
    })
  }

  blytheBefore() {
    return [
      { src: BlytheBefore1, alt: ''},
      { src: BlytheBefore2, alt: ''},
      { src: BlytheBefore3, alt: ''},
      { src: BlytheBefore4, alt: ''},
    ]
  }

  blytheDuring() {
    return [
      { src: BlytheDuring1, alt: ''},
      { src: BlytheDuring2, alt: ''},
      { src: BlytheDuring3, alt: ''},
      { src: BlytheDuring4, alt: ''},
    ]
  }

  blytheAfter() {
    return [
      { src: BlytheAfter1, alt: ''},
      { src: BlytheAfter2, alt: ''},
      { src: BlytheAfter3, alt: ''},
      { src: BlytheAfter4, alt: ''}
    ]
  }

  christiansenBefore() {
    return [
      { src: ChristiansenBefore1, alt: ''},
      { src: ChristiansenBefore2, alt: ''},
      { src: ChristiansenBefore3, alt: ''},
      { src: ChristiansenBefore4, alt: ''},
    ]
  }

  christiansenDuring() {
    return [
      { src: ChristiansenDuring1, alt: ''},
      { src: ChristiansenDuring2, alt: ''},
      { src: ChristiansenDuring3, alt: ''},
      { src: ChristiansenDuring4, alt: ''},
    ]
  }

  christiansenAfter() {
    return [
      { src: ChristiansenAfter1, alt: ''},
      { src: ChristiansenAfter2, alt: ''},
      { src: ChristiansenAfter3, alt: ''},
      { src: ChristiansenAfter4, alt: ''}
    ]
  }

  cleanupsBefore() {
    return [
      { src: CleanupsBefore1, alt: ''},
      { src: CleanupsBefore2, alt: ''},
    ]
  }

  cleanupsAfter() {
    return [
      { src: CleanupsAfter1, alt: ''},
      { src: CleanupsAfter2, alt: ''},
      { src: CleanupsAfter3, alt: ''},
      { src: CleanupsAfter4, alt: ''}
    ]
  }

  frontyardredesignDuring() {
    return [
      { src: FrontYardDuring1, alt: ''},
      { src: FrontYardDuring2, alt: ''},
      { src: FrontYardDuring3, alt: ''},
      { src: FrontYardDuring4, alt: ''},
    ]
  }

  frontyardredesignAfter() {
    return [
      { src: FrontYardAfter1, alt: ''},
      { src: FrontYardAfter2, alt: ''},
      { src: FrontYardAfter3, alt: ''},
      { src: FrontYardAfter4, alt: ''},
    ]
  }

  drainrockDuring() {
    return [
      { src: DrainRockDuring1, alt: ''},
      { src: DrainRockDuring2, alt: ''},
      { src: DrainRockDuring3, alt: ''},
      { src: DrainRockDuring4, alt: ''},
    ]
  }

  drainrockAfter() {
    return [
      { src: DrainRockAfter1, alt: ''},
      { src: DrainRockAfter2, alt: ''},
    ]
  }

  leakDuring() {
    return [
      { src: MassiveLeakDuring1, alt: ''},
      { src: MassiveLeakDuring2, alt: ''},
      { src: MassiveLeakDuring3, alt: ''},
      { src: MassiveLeakDuring4, alt: ''},
    ]
  }

  leakAfter() {
    return [
      { src: MassiveLeakAfter1, alt: ''},
    ]
  }

  grassBefore() {
    return [
      { src: GrassRemovalBefore1, alt: ''},
    ]
  }

  grassDuring() {
    return [
      { src: GrassRemovalDuring1, alt: ''},
      { src: GrassRemovalDuring2, alt: ''},
      { src: GrassRemovalDuring3, alt: ''},
      { src: GrassRemovalDuring4, alt: ''},
    ]
  }

  grassAfter() {
    return [
      { src: GrassRemovalAfter1, alt: ''},
      { src: GrassRemovalAfter2, alt: ''},
    ]
  }

  concrete() {
    return [
      { src: Concrete1, alt: ''},
      { src: Concrete2, alt: ''},
    ]
  }

  changeBlytheState(state) {
    this.setState({
      blythe: state
    });
  }
  
  changeChristiansenState(state) {
    this.setState({
      christiansen: state
    });
  }

  changeCleanupsState(state) {
    this.setState({
      cleanups: state
    });
  }

  changeFrontyardState(state) {
    this.setState({
      frontyard: state
    });
  }

  changeDrainRockState(state) {
    this.setState({
      drainrock: state
    });
  }

  changeMassiveLeakState(state) {
    this.setState({
      leak: state
    });
  }

  changeGrassRemovalState(state) {
    this.setState({
      grass: state
    });
  }

  render() {
    const { blythe, christiansen, cleanups, frontyard, drainrock, leak, grass } = this.state;

    return (
      <div className="gallerypage">

        <div className='gallerypage__banner'>
          <div className='gallerypage__banner-logo'>
            <img onClick={this.goHome} src={Logo} alt='logo' />
          </div>

          <div className='gallerypage__buttons'>
            <GreenPhoneButton>CALL US {PHONE_NUMBER_STR}</GreenPhoneButton>
            <WhiteLinkButton url='/contact#freequote'>FREE ESTIMATE</WhiteLinkButton>
          </div>
        </div>

        <div className='gallerypage__body'>

          <div className='gallerypage__gallerysection'>
            <div className='gallerypage__gallerysection-icon'>
              <img src={GalleryIcon} alt='galleryicon' />
              <h1>Gallery</h1>
            </div>

            <div className='gallerypage__gallerysection-subheader'>
              Here are some of the projects we are most proud of. Let us know how we can help you! 
            </div>
          </div>

          <div className='gallerypage__projects'>

            <Element name="rock-install">
              <div className='gallerypage__projects-common'>
                <h2>Rock Installation</h2>
                <ToggleSwitch cb={this.changeBlytheState} before during after currState={'after'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {blythe === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-before-1' onClick={ () => this.openViewer('blythe', blythe, 0) } />}
                      {blythe === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-during-1' onClick={ () => this.openViewer('blythe', blythe, 0) } />}
                      {blythe === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-after-1' onClick={ () => this.openViewer('blythe', blythe, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {blythe === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-before-2' onClick={ () => this.openViewer('blythe', blythe, 1) } />}
                      {blythe === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-during-2' onClick={ () => this.openViewer('blythe', blythe, 1) } />}
                      {blythe === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-after-2' onClick={ () => this.openViewer('blythe', blythe, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {blythe === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-before-3' onClick={ () => this.openViewer('blythe', blythe, 2) } />}
                      {blythe === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-during-3' onClick={ () => this.openViewer('blythe', blythe, 2) } />}
                      {blythe === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-after-3' onClick={ () => this.openViewer('blythe', blythe, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {blythe === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-before-4' onClick={ () => this.openViewer('blythe', blythe, 3) } />}
                      {blythe === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-during-4' onClick={ () => this.openViewer('blythe', blythe, 3) } />}
                      {blythe === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-blythe-after-4' onClick={ () => this.openViewer('blythe', blythe, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="putting-green">
              <div className='gallerypage__projects-common'>
                <h2>Putting Green and Makeover</h2>
                <ToggleSwitch cb={this.changeChristiansenState} before during after currState={'after'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {christiansen === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-before-1' onClick={ () => this.openViewer('christiansen', christiansen, 0) } />}
                      {christiansen === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-during-1' onClick={ () => this.openViewer('christiansen', christiansen, 0) } />}
                      {christiansen === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-after-1' onClick={ () => this.openViewer('christiansen', christiansen, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {christiansen === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-before-2' onClick={ () => this.openViewer('christiansen', christiansen, 1) } />}
                      {christiansen === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-during-2' onClick={ () => this.openViewer('christiansen', christiansen, 1) } />}
                      {christiansen === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-after-2' onClick={ () => this.openViewer('christiansen', christiansen, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {christiansen === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-before-3' onClick={ () => this.openViewer('christiansen', christiansen, 2) } />}
                      {christiansen === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-during-3' onClick={ () => this.openViewer('christiansen', christiansen, 2) } />}
                      {christiansen === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-after-3' onClick={ () => this.openViewer('christiansen', christiansen, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {christiansen === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-before-4' onClick={ () => this.openViewer('christiansen', christiansen, 3) } />}
                      {christiansen === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-during-4' onClick={ () => this.openViewer('christiansen', christiansen, 3) } />}
                      {christiansen === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-christiansen-after-4' onClick={ () => this.openViewer('christiansen', christiansen, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="cleanups">
              <div className='gallerypage__projects-common'>
                <h2>Cleanups</h2>
                <ToggleSwitch cb={this.changeCleanupsState} before during={false} after currState={'after'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {cleanups === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-before-1' onClick={ () => this.openViewer('cleanups', cleanups, 0) } />}
                      {cleanups === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-after-1' src={CleanupsAfter1} alt='cleanups-after-1' onClick={ () => this.openViewer('cleanups', cleanups, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {cleanups === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-before-2' onClick={ () => this.openViewer('cleanups', cleanups, 1) } />}
                      {cleanups === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-after-2' onClick={ () => this.openViewer('cleanups', cleanups, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {cleanups === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-after-3' onClick={ () => this.openViewer('cleanups', cleanups, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {cleanups === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-cleanups-after-4' onClick={ () => this.openViewer('cleanups', cleanups, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="front-yard-redesign">
              <div className='gallerypage__projects-common gallerypage__frontyard'>
                <h2>Front Yard Redesign</h2>
                <ToggleSwitch cb={this.changeFrontyardState} during after currState={'after'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {frontyard === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-during-1' onClick={ () => this.openViewer('frontyard', frontyard, 0) } />}
                      {frontyard === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-after-1' onClick={ () => this.openViewer('frontyard', frontyard, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {frontyard === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-during-2' onClick={ () => this.openViewer('frontyard', frontyard, 1) } />}
                      {frontyard === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-after-2' onClick={ () => this.openViewer('frontyard', frontyard, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {frontyard === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-during-3' onClick={ () => this.openViewer('frontyard', frontyard, 2) } />}
                      {frontyard === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-after-3' onClick={ () => this.openViewer('frontyard', frontyard, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {frontyard === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-during-4' onClick={ () => this.openViewer('frontyard', frontyard, 3) } />}
                      {frontyard === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-frontyard-after-4' onClick={ () => this.openViewer('frontyard', frontyard, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="drain-and-rocks">
              <div className='gallerypage__projects-common gallerypage__drain'>
                <h2>Drain and Rock Installation</h2>
                <ToggleSwitch cb={this.changeDrainRockState} during after currState={'during'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {drainrock === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-during-1' onClick={ () => this.openViewer('drainrock', drainrock, 0) } />}
                      {drainrock === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-after-1' onClick={ () => this.openViewer('drainrock', drainrock, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {drainrock === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-during-2' onClick={ () => this.openViewer('drainrock', drainrock, 1) } />}
                      {drainrock === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-after-2' onClick={ () => this.openViewer('drainrock', drainrock, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {drainrock === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-during-3' onClick={ () => this.openViewer('drainrock', drainrock, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {drainrock === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-drainrock-during-4' onClick={ () => this.openViewer('drainrock', drainrock, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="leak-fix">
              <div className='gallerypage__projects-common gallerypage__leak'>
                <h2>Massive Leak Fix</h2>
                <ToggleSwitch cb={this.changeMassiveLeakState} during after currState={'during'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {leak === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-leak-during-1' onClick={ () => this.openViewer('leak', leak, 0) } />}
                      {leak === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-leak-after-1' onClick={ () => this.openViewer('leak', leak, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {leak === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-leak-during-2' onClick={ () => this.openViewer('leak', leak, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {leak === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-leak-during-3' onClick={ () => this.openViewer('leak', leak, 2) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {leak === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-leak-during-4' onClick={ () => this.openViewer('leak', leak, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="grass-removal">
              <div className='gallerypage__projects-common'>
                <h2>Grass Removal and Rock Installation</h2>
                <ToggleSwitch cb={this.changeGrassRemovalState} before during after currState={'during'}/>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {grass === 'before' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-before-1' onClick={ () => this.openViewer('grass', grass, 0) } />}
                      {grass === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-during-1' onClick={ () => this.openViewer('grass', grass, 0) } />}
                      {grass === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-after-1' onClick={ () => this.openViewer('grass', grass, 0) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {grass === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-during-2' onClick={ () => this.openViewer('grass', grass, 1) } />}
                      {grass === 'after' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-after-2' onClick={ () => this.openViewer('grass', grass, 1) } />}
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {grass === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-during-3' onClick={ () => this.openViewer('grass', grass, 2) } />}
                      </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      {grass === 'during' && <div className='gallerypage__projects-pic-common gallerypage__projects-pic-grass-during-4' onClick={ () => this.openViewer('grass', grass, 3) } />}
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="concrete">
              <div className='gallerypage__projects-common gallerypage__concrete'>
                <h2>Concrete Work</h2>
                <div className='row'>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      <div className='gallerypage__projects-pic-common gallerypage__projects-pic-concrete-1' onClick={ () => this.openViewer('concrete', '', 0) } />
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                    <div className='gallerypage__projects-pic'>
                      <div className='gallerypage__projects-pic-common gallerypage__projects-pic-concrete-2' onClick={ () => this.openViewer('concrete', '', 1) } />
                    </div>
                  </div>
                </div>
              </div>
            </Element>

          </div>        
        <Viewer
          visible={this.state.visible}
          onClose={() => { this.setState({ visible: false }); } }
          scalable={false}
          drag={true}
          attribute={false}
          zoomable={false}
          disableMouseZoom={true}
          rotatable={false}
          noImgDetails={true}
          images={this.state.images}
          activeIndex={this.state.activeIndex}
          zIndex={999999}
          defaultScale={1.1}
          onMaskClick={ () => this.setState({ visible: false }) }
        />
        </div>

        <SeventhSection />

      </div>
    )
  }

}

export default withRouter(GalleryPage);