import React, { Component } from 'react'

import './Button.scss';

class GreenButtonDisabled extends Component {

  render() {
    return (
      <div className="commonbutton greenbutton-disabled">
        {this.props.children}
      </div>
    )
  }

}

export default GreenButtonDisabled;