import React, { Component } from 'react'

import './ThreeWayToggle.scss';

class ThreeWayToggle extends Component {

  constructor(props){
    super(props);
    this.changeState = this.changeState.bind(this);

    this.state = {
      currState: 'after',
    };
  }

  componentDidMount() {
    this.setState({
      currState: this.props.currState
    });
  }

  changeState(state) {
    this.setState({
      currState: state
    });

    this.props.cb(state);
  }


  render() {
    const { currState } = this.state;

    let classNameBefore = '';
    let classNameDuring = '';
    let classNameAfter = '';

    switch (currState) {
      case 'before': 
        classNameBefore = 'threewaytoggle__selected'
        break;
      case 'during': 
        classNameDuring = 'threewaytoggle__selected';
        break;
      case 'after': 
        classNameAfter = 'threewaytoggle__selected';
        break;
      default: 
        break;
    }

    return (
      <div className='threewaytoggle'>

        {this.props.before === true && <div className={`threewaytoggle__switch threewaytoggle__before ${classNameBefore}`} onClick={ () => this.changeState('before')  }>
          Before
        </div>}
        {this.props.during === true && <div className={`threewaytoggle__switch threewaytoggle__during ${classNameDuring}`} onClick={ () => this.changeState('during') }>
          During
        </div>}
        {this.props.after === true && <div className={`threewaytoggle__switch threewaytoggle__after ${classNameAfter}`} onClick={ () => this.changeState('after')  }>
          After
        </div>}

      </div>
    );
  }
}


export default ThreeWayToggle;